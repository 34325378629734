import { Col, Container, Row } from 'react-bootstrap';
import vdoPartCss from './vdo.module.css';
// import swaIcon from  '../img/swa.png';
// import android from  '../img/android.png';
// import ios from  '../img/ios.png';
import Thumnail from '../../img/ThumnailBanner.webp'




function vdoPart() {
  return (
    <div className={vdoPartCss.App}>
     <div className={vdoPartCss.vdoBack}>
         <Container>
             <Row>
                 <Col className="col-12 col-sm-6 col-md-6 aos-init aos-animate"  data-aos="fade-right">
                    <a href="https://www.youtube.com/embed/Vi7NTiBDc0c?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https%3A%2F%2Fswaadhyayan.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1" id="widget2" target='_blanks'>
                        <div className={vdoPartCss.vdoBox}>
                          <img className={vdoPartCss.bannerVideo} src={Thumnail} alt="swa" />
                        {/* <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="YouTube video player" width="100%" height="100%"
                        src="https://www.youtube.com/embed/Vi7NTiBDc0c?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https%3A%2F%2Fswaadhyayan.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1" id="widget2"></iframe> */}
                        </div>

                    </a>
                 </Col>
                 <Col className="col-12 col-sm-6 col-md-6 my-4">
                    <div className={vdoPartCss.swaText}>SWA-ADHYAYAN</div>
                    <span className={vdoPartCss.adaptive}>Adaptive Digital Learning Platform</span>
                    <p className={vdoPartCss.swa_adhyayanText}>
                     Swa-Adhyayan is a powerful, adaptive and comprehensive E-learning,
                     Assessment and Sharing Platform for students to identify their learning
                     style and build their progressive proficiency.
                     Activities and assessments on this Digital Platform are mapped to the latest
                     curriculum which make the learning process more effective and adaptive.
                     The assessments for kindergarten to class 8 generate multiple reports and the
                     progress cards of the children, depicting various parameters.
                     It enables all the users to share useful resources and learning outcomes which are beneficial for interaction between the schools, teachers, parents and students.
                    </p>
                 </Col>

             </Row>
         </Container>
     </div>
    
     
    </div>
  );
}

export default vdoPart;
