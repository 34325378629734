import upSkills from  '../../img/UpSkillBanner.webp';
import banner1 from  '../../img/banner1.webp';
import banner2 from  '../../img/banner2.webp';
import banner3 from  '../../img/banner3.webp';
// import banner4 from  '../../img/banner4.webp';
// import vidBanner from '../../img/forVideobanner.webp'
// import video1 from '../../img/video1.mp4'

import BannerCss from './banner.module.css';
import { Carousel } from 'react-bootstrap';




function bannerCom() {
  return (
    <div className="App">
  <Carousel id='mainCarousel' >
      {/* <Carousel.Item interval={25000}>
      <a href="https://upskill.swaadhyayan.com/upskill?utm_source=Swa-adhyayan&utm_medium=Direct&utm_campaign=Upskill-Slider" target='_blank'>
        <img className={BannerCss.bannerImgs} src={upSkills}/>
      </a>
      </Carousel.Item> */}
      <Carousel.Item>
        <img className={BannerCss.bannerImgs} src={banner1}/>
      </Carousel.Item>
      <Carousel.Item>
        <img className={BannerCss.bannerImgs} src={banner2}/>
      </Carousel.Item>
      <Carousel.Item>
        <img className={BannerCss.bannerImgs} src={banner3}/>
      </Carousel.Item>

      {/* <Carousel.Item> */}
        {/* <img className={BannerCss.bannerImgs} src={video1}/> */}
        {/* <video className={BannerCss.videoSize} src={video1} autoPlay loop muted> </video> */}
        {/* <div className={BannerCss.videoSize}>
        <video className={BannerCss.innerVdo} src={video1} autoPlay loop muted> </video>
        <div className={BannerCss.myText}>
          <img className={BannerCss.bannerImgs} src={vidBanner}/> */}
          {/* <div className={BannerCss.textBox}>
            <div className={BannerCss.Head1}>Banner Heading</div>
            <div className={BannerCss.headCaption}>Insert Heading Caption Here..</div>
          </div> */}
          
        {/* </div> */}
        {/* </div> */}
      {/* </Carousel.Item> */}

      
   </Carousel>
    </div>
  );
}

export default bannerCom;
