import React, { useState } from 'react';
import CareerCss from './career.module.css';
import BannerImg from '../img/career/CAREER.png';
import resumeForm from '../img/career/hearing.png';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { BiRupee, BiArrowBack } from "react-icons/bi";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Col, Container, Row, Button } from 'react-bootstrap';
import InputField from './InputField';
import { Helmet } from 'react-helmet';


function Career() {
  
  

  const data = [
    {
      id: 1,
      position: 'Android Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for an Android Developer who possesses a passion for pushing mobile technologies to the limits. This Android app developer will work with our team of talented engineers to design and build the next generation of our mobile applications. Android programming works closely with other app development and technical teams.',
      postDay: '5 days ago'
    },
    {
      id: 2,
      position: 'IOS App Developer',
      experience: '1-10 Years',
      location: 'New Delhi',
      discrip: 'We are looking for an iOS developer who possesses a passion for pushing mobile technologies to the limits and will work with our team of talented engineers to design and build the next generation of our mobile applications. ',
      postDay: '25 days ago'
    },
    {
      id: 3,
      position: 'Software Trainee',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a motivated Software Engineer Intern to join our team! As a Software Engineer Intern, you will collaborate with our senior leaders on assignments. ',
      postDay: '30+ days ago'
    },
    {
      id: 4,
      position: 'PHP Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a PHP Developer who is dedicated to the craft, writes code that he/she is proud of and can hit the ground running. ',
      postDay: '10 days ago'

    },
    {
      id: 5,
      position: 'Sr. PHP Developer',
      experience: '1-12 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a Sr. PHP Developer who is dedicated to the craft, writes code that he/she is proud of and can hit the ground running. ',
      postDay: '5 days ago'
    },
    {
      id: 6,
      position: 'React Native Developer',
      experience: '1-4 Years',
      location: 'New Delhi',
      discrip: 'We are looking for a React Native developer interested in building performant mobile apps on both the iOS and Android platforms. You will be responsible for architecting and building these applications, as well as coordinating with the teams responsible for other layers of the product infrastructure. Building a product is a highly collaborative effort, and as such, a strong team player with a commitment to perfection is required. ',
      postDay: '22 days ago'
    },
  ];


  const inputs = [
    {
      id: 1,
      name: "userName",
      type: "text",
      placeholder: "Enter your full Name",
      label: "Full Name",
      autoComplete:'off',
      required: true,
    },
    {
      id: 2,
      name: "mobNumber",
      type: "number",
      placeholder: "Enter your mobile number",
      label: "Mobile Number",
      autoComplete:'off',
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
      label: "Email Id",
      autoComplete:'off'
    },
    {
      id: 4,
      name: "message",
      type: "text",
      placeholder: "Enter job title",
      label: "Job Title",
      required: true,
    },
    {
      id: 5,
      name: "uploadCv",
      type: "file",
      placeholder: "",
      label: "Upload CV",
      required: true,
    },
  ];

const [showPopup, setShowPopup] = useState(false)
const [selectedData, setSelectedData] = useState()

// offCanvas//
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
// offCanvas//


const [values, setValues] = useState({
  userName: "",
  mobNumber: "",
  email: "",
  message: "",
  uploadCv: "",
});

  function ReadMore(item) {
    setShowPopup(true)
    setSelectedData(item)
  }

  function closePopup() {
    setShowPopup(false)
  }

  const handleSubmit = (e) => {

    
    e.preventDefault();
    console.log(JSON.stringify(values) + 'hari')
    setShow(false);
  };

  const onChange = (e) => {
    setValues((prev)=>{
      return{...prev, [e.target.name]: e.target.value}
    })
  };

  return (
    <div className={CareerCss.carrerBk}>
      <Helmet>
        <title>Careers options for Information Technology, HR, Swa-Adhyayan</title>
        <meta name="description" content="- Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan" />

        <meta property="og:title" content="Careers options for Information Technology, HR, Swa-Adhyayan" /> 
        <meta property="og:description" content="Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan"/> 
        <meta property="og:url" content="https://swaadhyayan.com/Event" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/CAREER.95735547.png" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Careers options for Information Technology, HR, Swa-Adhyayan"/>
        <meta name="twitter:description" content="Give Wings to Your Career in the field of IT by grabbing opportunity of joining us an Mobile App, Web Developer and Software Trainee Professional- Swa-Adhyayan" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/CAREER.95735547.png" />

      </Helmet>

      {/* Banner Section */}
      <div className={CareerCss.banner}>
        <img className={CareerCss.bannerImg} src={BannerImg} />
      </div>
      {/* Banner Section */}


      {/* Second Section */}
      <div className={CareerCss.bgdiv}>
        <Container>
          {!showPopup &&
            <Row className="" data-aos="zoom-in" data-aos-duration="1500">
              {/* <div className={CareerCss.Heading}>CAREER OPPORTUNITIES </div> */}

              {data.map((item, index) => {
                let shortDiscription = ''
                if (item.discrip.length > 95) {
                  shortDiscription = item.discrip.substring(0, 92) + '...'

                }
                return (
                  <Col className="col-md-4 col-sm-6 col-12 mt-3">
                    <div className={CareerCss.containerBox}>
                      <div className={CareerCss.jobHead}>{item.position}</div>
                      <div className={CareerCss.experience}>
                        <div className={CareerCss.item}><WorkOutlineIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{item.experience}</div>
                        <div className={CareerCss.item}><BiRupee style={{ fontSize: '23px', color: '#b1b0b0', marginRight: '6px' }} />Not disclosed</div>
                        <div className={CareerCss.item}><LocationOnOutlinedIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{item.location}</div>
                      </div>
                      <div className={CareerCss.discription}>
                        <div className="">Job discription:</div>
                        <div className="">{shortDiscription}</div>
                      </div>
                      <div className={CareerCss.btnDiv}>
                        <div className={CareerCss.postDate}>{item.postDay}</div>
                        <div>
                          <button className='btn btn-primary btn-sm' onClick={() => ReadMore(item)}>Read More</button>
                        </div>
                      </div>

                    </div>

                  </Col>
                )
              })}
            </Row>
          }

          {showPopup &&
            <div className={CareerCss.popup1}>
              <Container className={CareerCss.innerDiv}>
                <div className={CareerCss.jobPosition}>
                  <div className={CareerCss.buttonDiv}
                    onClick={closePopup}
                  >
                    <BiArrowBack/>BACK
                  </div>

                  <div className={CareerCss.positionHead}>{selectedData.position}</div>
                  <div className={CareerCss.items}>

                    <div className="">
                      <div className={CareerCss.point}><WorkOutlineIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{selectedData.experience}</div>
                      <div className={CareerCss.point}><BiRupee style={{ fontSize: '23px', color: '#b1b0b0', marginRight: '6px' }} />Not disclosed</div>
                      <div className={CareerCss.point}><LocationOnOutlinedIcon style={{ fontSize: '20px', color: '#b1b0b0', marginRight: '6px' }} />{selectedData.location}</div>
                    </div>

                    <div className={CareerCss.applyBtn}>
                      {/* <button className='btn btn-secondary' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">APPLY</button> */}

                     <Button variant="primary" onClick={handleShow}>APPLY</Button>

                    </div>
                  </div>
                  <div className={CareerCss.jobpostDate}>
                    <div style={{ borderRight: '1px solid gray', padding: '0 10px' }}>Posted: <span style={{ fontWeight: '500' }}>{selectedData.postDay}</span></div>
                    <div style={{ padding: '0 10px' }}>Openings: <span style={{ fontWeight: '500' }}>1</span></div>

                  </div>
                </div>


                <div className={CareerCss.jobDiscription}>
                  <div className={CareerCss.subHead}>Job Description:</div>
                  <div className="pb-2">{selectedData.discrip}</div>

                  {selectedData.id == 1 &&
                    <>
                      <div className={CareerCss.subHead}>Responsibilities:</div>
                      <ul>
                        <li>Design and build advanced applications for the Android platform</li>
                        <li>Collaborate with cross-functional teams to define, design, and ship new features</li>
                        <li>Work with outside data sources and APIs</li>
                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability</li>
                        <li>Work on bug fixing and improving application performance</li>
                        <li>Continuously discover, evaluate, and implement new technologies to maximise development efficiency</li>
                      </ul>
                      <div className={CareerCss.subHead}>Requirements and Skills:</div>
                      <ul>
                        <li>BS/MS degree in Computer Science, Engineering or a related subject</li>
                        <li>Proven software development experience and Android skills development</li>
                        <li>Proven working experience in Android app development and</li>
                        <li>Got published at least one original Android app</li>
                        <li>Experience with Android SDK</li>
                        <li>Experience working with remote data via REST and JSON</li>
                        <li>Experience with third-party libraries and APIs</li>
                        <li>Working knowledge of the general mobile landscape, architectures, trends, and emerging technologies</li>
                        <li>Solid understanding of the full mobile development life cycle</li>
                      </ul>
                    </>

                  }


                  {/*  ios dev*/}
                  {selectedData.id == 2 &&
                    <>
                      <div className={CareerCss.subHead}>Responsibilities:</div>
                      <ul>
                        <li>Design and build advanced applications for the iOS platform</li>
                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                        <li>Unit-test code for robustness, including edge cases, usability, and general reliability.</li>
                        <li>Work on bug fixing and improving application performance.</li>
                        <li>Continuously discover, evaluate, and implement new technologies to maximise development efficiency.</li>
                      </ul>
                      <div className={CareerCss.subHead}>Requirements and Skills:</div>
                      <ul>
                        <li>BS/MS degree in Computer Science, Engineering or a related subject</li>
                        <li>Proven software development experience and Android skills development</li>
                        <li>Working experience in iOS development</li>
                        <li>Got published one or more iOS apps in the app store</li>
                        <li>A deep familiarity with Objective-C and Cocoa Touch</li>
                        <li>Experience working with iOS frameworks such as Core Data, Core Animation, Core Graphics and Core Text</li>
                        <li>Experience with third-party libraries and APIs</li>
                        <li>Working knowledge of the general mobile landscape, architectures, trends, and emerging technologies</li>
                        <li>Solid understanding of the full mobile development life cycle.</li>
                      </ul>
                    </>
                  }
                  {/*  ios dev*/}


                  {/*  trainee*/}

                  {selectedData.id == 3 &&
                    <>
                      <div className={CareerCss.subHead}>Responsibilities:</div>
                      <ul>
                        <li>Developing applications (coding, programming)</li>
                        <li>Debugging and testing code</li>
                        <li>Documenting and testing new software applications</li>
                        <li>Researching, investigating and fixing a wide range of technical issues</li>
                        <li>Collaborating with senior leaders</li>
                        <li>Approaching problems and creating solutions</li>
                        <li>Proactively learning about new technologies</li>
                      </ul>
                      <div className={CareerCss.subHead}>Requirements and Skills:</div>
                      <ul>
                        <li>Bachelor's Degree in Computer Science, Software Engineering or related area</li>
                        <li>Experience with various programming languages</li>
                        <li>Strong communication and presentation skills</li>
                        <li>Self-motivated and results-oriented</li>
                        <li>Ability to interact with cross-functional teams</li>
                        <li>Strong problem-solving abilities</li>
                        <li>Highly developed analytical and organisational skills</li>
                        <li>Ability to work in a fast-paced environment</li>
                      </ul>
                    </>
                  }
                  {/*  trainee*/}


                  {/*  php dev*/}
                  {(selectedData.id == 4 || selectedData.id == 5) &&
                    <>
                      <div className={CareerCss.subHead}>Responsibilities:</div>
                      <ul>
                        <li>Write “clean”, well-designed code</li>
                        <li>Produce detailed specifications</li>
                        <li>Troubleshoot, test and maintain the core product software and databases to ensure strong optimisation and functionality</li>
                        <li>Contribute in all phases of the development lifecycle</li>
                        <li>Follow industry best practices</li>
                        <li>Develop and deploy new features to facilitate related procedures and tools if necessary</li>
                      </ul>
                      <div className={CareerCss.subHead}>Requirements and Skills:</div>
                      <ul>
                        <li>Proven software development experience in PHP</li>
                        <li>Understanding of open source projects like Joomla, Drupal, Wikis, osCommerce, etc.</li>
                        <li>Demonstrable knowledge of web technologies including HTML, CSS, Javascript, AJAX, etc.</li>
                        <li>Good knowledge of relational databases, version control tools and of developing web services</li>
                        <li>Experience in common third-party APIs (Google, Facebook, eBay, etc.)</li>
                        <li>Passion for best design and coding practices and a desire to develop new bold ideas</li>
                        <li>Highly developed analytical and organisational skills</li>
                        <li>BS/MS degree in Computer Science, Engineering or a related subject</li>
                      </ul>
                    </>
                  }
                  {/*  php dev*/}


                  {/*  RN dev*/}

                  {selectedData.id == 6 &&
                    <>
                      <div className={CareerCss.subHead}>Responsibilities:</div>
                      <ul>
                        <li>Build pixel-perfect, buttery smooth UIs across both mobile platforms.</li>
                        <li>Leverage native APIs for deep integrations with both platforms.</li>
                        <li>Diagnose and fix bugs and performance bottlenecks for performance that feels native.</li>
                        <li>Reach out to the open source community to encourage and help implement mission-critical software fixes—React Native moves fast and often breaks things.</li>
                        <li>Maintain code and write automated tests to ensure the product is of the highest quality.</li>
                        <li>Transition existing React web apps to React Native.</li>
                      </ul>
                      <div className={CareerCss.subHead}>Requirements and Skills:</div>
                      <ul>
                        <li>Firm grasp of the JavaScript {'{and TypeScript or ClojureScript}'} language and its nuances, including ES6+ syntax</li>
                        <li>Knowledge of {'{functional or object-oriented}'} programming</li>
                        <li>Ability to write well-documented, clean Javascript code</li>
                        <li>Rock solid at working with third-party dependencies and debugging dependency conflicts</li>
                        <li>Familiarity with native build tools, like XCode, Gradle {'{Android Studio, IntelliJ}'}</li>
                        <li>Understanding of REST APIs, the document request model, and offline storage</li>
                        <li>Experience with automated testing suites, like {'{Jest or Mocha}'}</li>
                        <li>{'{Make sure to mention any other frameworks, libraries, or other technologies relevant to your project}'}</li>
                      </ul>
                    </>
                  }
                  {/*  RN dev*/}



                </div>
                <div className={CareerCss.companyProfile}>
                  <div className={CareerCss.subHead}>About Company</div>
                  <div className="">Swa-Adhyayan is a product or package designed and led by a team of visionaries, educators and professionals to captivate the attention of learners, improve their talents and escalate their intellects. This platform went digital at a time when the teaching-learning process turned more towards online learning. It helped many institutions to educate students continuously with its hybrid learning.<br />
                    This Adaptive Digital Learning Platform (ADLP) includes features such as SEPT (Swa-Adhyayan Entrant’s Profiling Test), Swa-learning, Swa-assessment, Swa-sharing, Homework app, and Reports. The Assessment for classes 1 to 8 generates multiple tests and reports and the progress cards of the children based on numerous parameters. It is supported by textbooks, workbooks, teachers’ resource manuals, competitive kits, NCERT kits, CBSE Pariksha, etc. Its features, ‘Learning by Gamification’ improves the kinesthetic skills of the learners, adding to experiential learning or hands-on learning. The purpose of Swa-Adhyayan is to deliver this product globally and to ensure that learners acquire better learning outcome by using the resources shared in this platform.</div>
                </div>

              </Container>
            </div>
          }

        </Container>
      </div>
      {/* Second Section */}

      <Offcanvas show={show} onHide={handleClose} backdrop="static" placement = 'end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply for: </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={CareerCss.formContainer}>
              <form onSubmit={handleSubmit}  action="https://formspree.io/f/xlekdown" method="POST">
                  {inputs.map((item, index)=>{
                    return(
                      <InputField
                      key = {item.id}
                      {...item}
                      value = {values[item.userName]}
                      onChange = {onChange}
                      />
                    )
                  })}
                {/* <div className={CareerCss.inputContainer}>
                  <div className="">Job description<span className={CareerCss.formstartMark} >*</span></div>
                  <textarea className={CareerCss.inputStyle} required name="Message"></textarea>
                </div> */}
                <Button  style={{width:'100%'}} type="submit" variant="primary">Submit</Button>
              </form>

          </div>
        </Offcanvas.Body>
      </Offcanvas>

      


    </div>
  );
}
export default Career;
