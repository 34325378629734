import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ClismpseCss from "./GlimpseDashboard.module.css"

// -----------------img---------------------
import Student from "../img/services/parent.webp"
import Parent from "../img/services/student.webp"
import Teacher from "../img/services/Teacher.webp"
import School from "../img/services/School.webp"
// -----------------img---------------------
import { FaUserGraduate } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { FaSchool} from "react-icons/fa";


const GlimpseDashboard = () => {

  return (
    <>
      <section id="services" className={ClismpseCss.services}>
        <div className="container" data-aos="fade-up">

          <div className={`my-4 ${ClismpseCss.section_header}`}>
            <h2>Our Services</h2>
          </div>

          <div className="row gy-2">
            <div className="col-xl-3 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="200">
              <div className={ClismpseCss.service_item}>
                <div className={ClismpseCss.img}>
                  <img src={Student} className="img-fluid" alt="" />
                </div>
                <div className={`position-relative ${ClismpseCss.details}`}>
                  <div className={ClismpseCss.icon}>
                    <FaUserGraduate />
                  </div>
                  <h6>For Students</h6>
                  <ul>
                    <li>SEPT</li>
                    <li>Learning</li>
                    <li>Assessment</li>
                    <li>Practice Test</li>
                    <li>Progress Report</li>
                    <li>Homework</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="200">
              <div className={ClismpseCss.service_item}>
                <div className={ClismpseCss.img}>
                  <img src={Parent} className="img-fluid" alt="" />
                </div>
                <div className={`position-relative ${ClismpseCss.details}`}>
                  <div className={ClismpseCss.icon}>
                    <RiParentFill />
                  </div>
                  <h6>  For Parents</h6>
                  <ul>
                    <li>Student's Report</li>
                    <li>Notes and Notifications</li>
                    <li>Practice Lessons for a Child</li>
                    <li>Share Feedback</li>
                    <li>Child's Progress Tracking</li>
                    <li>Homework</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="200">
              <div className={ClismpseCss.service_item}>
                <div className={ClismpseCss.img}>
                  <img src={Teacher} className="img-fluid" alt="" />
                </div>
                <div className={`position-relative ${ClismpseCss.details}`}>
                  <div className={ClismpseCss.icon}>
                    <FaChalkboardTeacher />
                  </div>
                  <h6> For Teachers</h6>
                  <ul>
                    <li>Assign Learing</li>
                    <li>Assign Assessment</li>
                    <li>Generate Test</li>
                    <li> Student's Attendance</li>
                    <li>Sharing</li>
                    <li> Homework</li>
                  </ul>
                </div>
              </div>
            </div>

            

            <div className="col-xl-3 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="200">
              <div className={ClismpseCss.service_item}>
                <div className={ClismpseCss.img}>
                  <img src={School} className="img-fluid" alt="" />
                </div>
                <div className={`position-relative ${ClismpseCss.details}`}>
                  <div className={ClismpseCss.icon}>
                    <FaSchool />
                  </div>
                  <h6>For Schools</h6>
                  <ul>
                    <li>Managing Teachers</li>
                    <li>Managing Students</li>
                    <li>Managing Reports</li>
                    <li> Managing Attendance</li>
                    <li>Managing Timetables</li>
                    <li>Sharing</li>
                  </ul>
                </div>
              </div>
            </div>

            
          </div>

        </div>
      </section>
    </>
  )
}

export default GlimpseDashboard
