
import react, {useState} from 'react'
import Banner from './banner/Banner';
import Products from './product/Products';
import Vdo_part from './video_part/Vdo_part';
import ReadyTo from './readyTo/ReadyTo';
import Offer from './offer/Offer';
import Vdogallery  from './vdogallery/Vdogallery';
import Testimonial  from './testimonial/Testimonial';
import Toplevel  from './toplevel/Toplevel';
import OurTeam  from './ourteam/Ourteam';
import NewLunching from './NewLunching';
import PanIndiaUserMap from './PanIndiaMap/PanIndiaUserMap';
import OnlineProduct from '../OurProduct/OnlineProduct'
import OfflineProduct from '../OurProduct/OfflineProduct';
import Feedback from './parentStory/Experience';
import popup from '../../components/img/winner.webp'
// import popup from '../../components/img/demoLogin.gif'
import Offline2Product from '../OurProduct/OffLine2Product';
function Home() {

  const [showPopup, setPopup]=useState(true);


setTimeout(function() {
    window.$('#popupD').fadeOut('fast');
}, 15000);

function closePopup(){
    setPopup(false)
}

  
 
  return (
    <div className="App">
      <Banner/>
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <Products/>
      </div>
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <Vdo_part/>
      </div>
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <Offer/>
      </div>
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <ReadyTo/>
      </div>
      {/* <OnlineProduct/> */}
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <OfflineProduct/>
      </div>
      {/* <Offline2Product/> */}
      {/* <div data-aos="zoom-in"  data-aos-duration="1500">
        <Vdogallery/>
      </div> */}
      <div data-aos="zoom-in"  data-aos-duration="1500">
        <Testimonial/>
      </div>
      {/* <div data-aos="zoom-in"  data-aos-duration="1500">
        <Feedback/>
      </div> */}
      <div style={{backgroundColor:'#bcdfff', padding:'50px, 0'}}>
        <PanIndiaUserMap/>
      </div>
      {/* <Toplevel/> */}
      {/* <OurTeam/> */}


{/* popup */}
      {showPopup &&
   <div id='popupD' className='popupD'>
      <div className='imgPopup'>
          <div className="closebtn" onClick={closePopup}>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
          </div>
              <img src={popup} alt=""/>
      </div>
  </div>
}
{/* popup */}
      
   </div>




  );
}

export default Home;
