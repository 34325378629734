import { useState } from 'react';
import { Col, Container, Row} from 'react-bootstrap';
import ReadyToCss from './readyto.module.css';
import ReadyToImg from  '../../img/readyto.png';
import { Link } from "react-router-dom";
import { OfflinePopup } from './OfflinePopup';
// import OfflinePopup from './OfflinePopup'

function ReadyTo() {
  const [popstate, setPopstate] = useState(false);
   function OfflinePop(){
    setPopstate(true)
   }
  
   function closePopup(){
    setPopstate(false)
   }
  return (
    <div className={ReadyToCss.readytoBack}>
      <Container className={ReadyToCss.mainContainer}>
        <Row>
          <Col className="col-12 col-md-6 col-sm-6">
            <img className={ReadyToCss.imgReady} src={ReadyToImg}/>
          </Col>
          <Col className="col-12 col-md-6 col-sm-6">
            <div className={ReadyToCss.startYour}>START YOUR DEMO TOUR...</div>
            {/* <button className={ReadyToCss.buttonDivin}><a href="https://swaadhyayan.com/lms/demo/login" target="_blank">Get Started</a></button> */}
        
              <button className={ReadyToCss.buttonDivin} title='Book your free demo today'><a href="https://swaadhyayan.com/lms/demo/login" target="_blank">ONLINE DEMO</a></button>
            <button className={ReadyToCss.buttonDivin} onClick={OfflinePop} >OFFLINE DEMO</button>
            <OfflinePopup show={popstate} showPopup={OfflinePop} closePopup={closePopup}/>
          
          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default ReadyTo;
