import ServicesCss from '././services.module.css';
import ServicesBanner from '../img/services/ServicesBanner.webp';

import { Col, Container, Row } from 'react-bootstrap';

import Offline from '../OnlineOffline/Offline';

import GlimpseDasboard from "./GlimpseDashboard"
import OnlineOffring from "./OnlineOffring";
import Forteacher from './Forteacher';
import { Helmet } from 'react-helmet';


function Services() {
  

  return (
    <>

      <Helmet>
          <title>Swa-Adhyayan Services SEPT | Swa-Learning | Swa-Assessment | Swa-Sharing</title>
          <meta name="description" content="The most reputed E-learning platform for students, Parents, Teachers and Schools is a flexible way to learn via digital resources and study material anywhere, anytime." />


          <meta property="og:title" content="Swa-Adhyayan Services SEPT | Swa-Learning | Swa-Assessment | Swa-Sharing" /> 
          <meta property="og:description" content="The most reputed E-learning platform for students, Parents, Teachers and Schools is a flexible way to learn via digital resources and study material anywhere, anytime"/> 
          <meta property="og:url" content="https://swaadhyayan.com/Services" /> 
          <meta property="og:site_name" content="Swa Adhyayan" /> 
          <meta property="og:image" content="https://swaadhyayan.com/static/media/ServicesBanner.43931592.webp" /> 
          <meta name="twitter:site" content="@swaadhyayan"/> 
          <meta name="twitter:title" content="Swa-Adhyayan Services SEPT | Swa-Learning | Swa-Assessment | Swa-Sharing"/>
          <meta name="twitter:description" content="The most reputed E-learning platform for students, Parents, Teachers and Schools is a flexible way to learn via digital resources and study material anywhere, anytime" />
          <meta name="twitter:image" content="https://swaadhyayan.com/static/media/ServicesBanner.43931592.webp" />
      </Helmet>
      
      {/* Banner Section */}
      <div className={ServicesCss.BannerBg}>
        <img className={ServicesCss.bannerDiv} src={ServicesBanner} />
      </div>
      {/* Banner Section */}

      <div className="pb-4 my-5"  data-aos="zoom-in"  data-aos-duration="1500">
        <GlimpseDasboard />
      </div>

      <div className="mt-5 pb-1 bg-light">
        <OnlineOffring />
      </div>
      

      <div className={ServicesCss.OfflineDiv} data-aos="zoom-in"  data-aos-duration="1500"> 
          <Container>
          <Row className='g-0'>
            <Col className={`col-md-12 col-sm-12 col-12 ${ServicesCss.colBorder}`}>
              <Offline/>
            </Col>
          </Row>
          </Container>
        </div>
        <div data-aos="zoom-in"  data-aos-duration="1500">
        <Forteacher/>
      </div>
    </>
  );
}

export default Services;
