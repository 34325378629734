import {React, useEffect, useState} from 'react'
import ScrollCss from './Scroll.module.css';
import { HiArrowUp } from "react-icons/hi";
import RocketIcon from './img/rocket.svg'
import whatsAppImg from './img/whatsapp.png'
import cuteLive from './img/UpskillCircle.png'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';

const ScrolTop = () => {

    const [goScrollTop, setGoScrollTop] = useState(false)

    function goTop(){
        window.scrollTo({top:0, behavior:'smooth'})    
    }

    function checkScroll(){
        let heightToHidden = 250;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if(windowScroll > heightToHidden){
            setGoScrollTop (true)
        }else{
            setGoScrollTop (false)
        }

    }

    useEffect(()=>{
        window.addEventListener('scroll', checkScroll)
        return ()=> window.removeEventListener('scroll', checkScroll)

    }, [])
  return (
    <>

        {/* <a  href="https://upskill.swaadhyayan.com/upskill?utm_source=Swa-adhyayan&utm_medium=Direct&utm_campaign=Upskill-Floating_Button" target="_blank">
            <div className={`${ScrollCss.cuetLive}`}>
                <img src={cuteLive} className={`${ScrollCss.cuetLiveImg}`} alt="" />
            </div>
        </a> */}

            {goScrollTop && 
                <div className={`${ScrollCss.btnTop}`} onClick={goTop}>
                    {/* <HiArrowUp className={`${ScrollCss.arrow}`}/> */}
                    {/* <RocketIcon/> */}
                    <img src={RocketIcon} alt="" className={`${ScrollCss.arrow}`}/>
                </div>
            }

            <a href="https://api.whatsapp.com/send?phone=919717998857&text=" target="_blank">
                <div className={ScrollCss.chatBtn}>
                    <img className={ScrollCss.Whatsappimg} src={whatsAppImg} alt="" />
                </div>
            </a>

    </>
  )
}


export default ScrolTop;
