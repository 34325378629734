import { Container} from 'react-bootstrap';
import VdogalleryCss from './vdogallery.module.css';

import { useEffect, useRef } from 'react';

function Ourgallery() {

    const players = useRef([])

    useEffect(()=>{
        window.$("#OurgallerySlider").owlCarousel({
        items:2,
        itemsDesktop:[1000,2],
        itemsDesktopSmall:[979,2],
        itemsTablet:[768,1],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        // autoPlay:true
        }); 

        loadVideo();

    },  []);
    
    function loadVideo(){
        
        if(!window.YT){
            let tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            console.log("making new video");
            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

        } else {
            onYouTubeIframeAPIReady()
        }
    }



    function selectedVideo(val) {
        console.log(val);
        
    }
    function onYouTubeIframeAPIReady() {
        // let youTubesIframes = document.getElementsByClassName(`${VdogalleryCss.vdoYoutube}`);
        console.log("play video")
        
        players.current[0]= new window.YT.Player('vid1', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{console.log('video ready')},
                'onStateChange':(event)=>{stopVideo(event, 0)}
            }
        })
        players.current[1]= new window.YT.Player('vid2', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 1)}
            }
        })
        players.current[2]= new window.YT.Player('vid3', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 2)}
            }
        })

        players.current[3]= new window.YT.Player('vid4', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 3)}
            }
        })
        players.current[4]= new window.YT.Player('vid5', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 4)}
            }
        })
        players.current[5]= new window.YT.Player('vid6', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 5)}
            }
        })
        players.current[6]= new window.YT.Player('vid7', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 6)}
            }
        })
        players.current[7]= new window.YT.Player('vid8', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 7)}
            }
        })
        players.current[8]= new window.YT.Player('vid9', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 8)}
            }
        })
        players.current[9]= new window.YT.Player('vid10', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 9)}
            }
        })
        players.current[10]= new window.YT.Player('vid11', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 10)}
            }
        })
        players.current[11]= new window.YT.Player('vid12', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 11)}
            }
        })
        players.current[12]= new window.YT.Player('vid13', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 12)}
            }
        })
        players.current[13]= new window.YT.Player('vid14', {
            events: {
                // call this function when player is ready to use
                'onReady': (event)=>{},
                'onStateChange':(event)=>{stopVideo(event, 13)}
            }
        })
      
        


    }

    function stopVideo(event, num){
      
        if(event.data==2) return;
        for(const [ind, vid] of players.current.entries()){
            if(ind!=num){
                vid.pauseVideo()
            }
        }
    }
    
  return (
    <>
    
    <div className={VdogalleryCss.Ourgallery}>
    {/* <div className={VdogalleryCss.testimonialHeading}>EVENTS</div> */}
        <Container>
        <div id="OurgallerySlider" className={VdogalleryCss.owl_carousel}>
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe onClick={selectedVideo}  className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/qhKkKn5Qgzs?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid1"></iframe>  
                    <div className={VdogalleryCss.caption}>Mr Sandeep Pai S, Surana Vidyalaya, Bengaluru</div>
                    </div>
                </div>
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/tceD7i_IW1Y?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid2"></iframe> 

                    <div className={VdogalleryCss.caption}>Mrs Aradhana Kabi, National Public School, ITPL, Bengaluru</div>
                    </div>
                </div>

                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/z2G5bFnqFik?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid3"></iframe> 
                    <div className={VdogalleryCss.caption}>Mrs Bharti Tanwar,  Gangotri International School, Gujarat</div>
                    </div>
                </div>

                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/KVCe1YgWG8s?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid4"></iframe> 
                    <div className={VdogalleryCss.caption}>Mrs Janet George, SSRVM Vignan Nagar, Bengaluru</div>
                    </div>
                </div>

                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/pntDzgAcPSY?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid5"></iframe> 
                    <div className={VdogalleryCss.caption}>Mrs Manila Carvalho, Delhi Public School, Bengaluru East</div>
                    </div>
                </div>

               <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/m9cX2PE6sCE?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid6"></iframe> 
                    <div className={VdogalleryCss.caption}>Mrs Vamshree Priya Amar, Chairperson, BSSCA Principal, MVJ  International School, Bengaluru</div>
                    </div>
                </div>

                {/* 7 */}
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/h1MCj9nSoXc?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid7"></iframe> 
                    <div className={VdogalleryCss.caption}>Mrs. Anupama Ramachandra, Secretary, BSSCA Principal, Delhi Public School, E-City</div>
                    </div>
                </div>
                {/* 7 */}


                 {/* 8 */}
                 <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/h1BtZ49DDpA?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid8"></iframe> 
                    <div className={VdogalleryCss.caption}>Tripti Asnayak, KAM Public School</div>
                    </div>
                </div>
               {/* 8 */}

                {/* 9 */}
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/y5EgHjzFmVc?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid9"></iframe> 
                    <div className={VdogalleryCss.caption}>Sneha Sandeep, St. Anne's Public School</div>
                    </div>
                </div>
               {/* 9 */}

               {/*10 */}
               <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/nqFpCBXU3kg?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid10"></iframe> 
                    <div className={VdogalleryCss.caption}>Renu Josu, Principal, Mary Matha Public School</div>
                    </div>
                </div>
               {/* 10 */}

                {/*11 */}
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/VvBJC4dKMLU?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid11"></iframe> 
                    <div className={VdogalleryCss.caption}>Niruprani and Hasbi Riyas, National Public School</div>
                    </div>
                </div>
               {/* 11 */}

                {/*12 */}
                <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/XgQf4jSE1bA?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid12"></iframe> 
                    <div className={VdogalleryCss.caption}>Mr. Nair, Master Trainer From NCERT</div>
                    </div>
                </div>
               {/* 12 */}

               {/*13 */}
               <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/SLnWcwtYKsU?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid13"></iframe> 
                    <div className={VdogalleryCss.caption}>Jayaprabha Pradeep, Principal of Gregorian Public School, Kerala</div>
                    </div>
                </div>
               {/* 13 */}

               {/*14 */}
               <div className={VdogalleryCss.testimonial}>
                    <div className={VdogalleryCss.pic}>
                    <iframe className={VdogalleryCss.vdoYoutube} src="https://www.youtube.com/embed/dntrNGDw2XU?showinfo=0&enablejsapi=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen id="vid14"></iframe> 
                    <div className={VdogalleryCss.caption}>Danya Ajay, SDKY Gurukula Vidyalaya, Kerala</div>
                    </div>
                </div>
               {/* 14 */}
               
            
                
                
                
               
            </div>

        </Container>
    </div>
    </>
  );
}

export default Ourgallery;
