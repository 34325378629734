import TermsCss from './terms.module.css';
import Banner from '../img/trm/banner.png';
import AlbumIcon from '@mui/icons-material/Album';

import { Container} from 'react-bootstrap';


function Terms() {

  return (
    <div className={TermsCss.termsBG}>
        <img className={TermsCss.banner} src={Banner} alt="Banner"/>
        <Container>
        <div className={TermsCss.textContant}>
          {/* <div className={TermsCss.heading}><AlbumIcon className={TermsCss.iconsLeft}/></div>
          <div className={TermsCss.runingText}>The term ‘User’ shall refer to the user who is browsing the Website. The term ‘Swa-Adhyayan’ shall refer to Swa-Adhyayan and/or its affiliates/subsidiary companies. The term ‘Site’ refers to <a href="/" className="text-primary">www.swaadhyayan.com</a> .
          </div> */}
          <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                  The term ‘User’ shall refer to the user who is browsing the Website. The term ‘Swa-Adhyayan’ shall refer to Swa-Adhyayan and/or its affiliates/subsidiary companies. The term ‘Site’ refers to <a href="/" className="text-primary">www.swaadhyayan.com</a> .
                  </div>
            </div>


            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                  By using the Site, you agree to follow and be bound by the following terms and conditions concerning your use of the Site. Swa-Adhyayan may revise the Terms of Use at any time without any notice.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                    All the content present on this site is the exclusive property of Swa-Adhyayan. The software, text, images, graphics, 
                     video and audio used on this site solely belong to Swa-Adhyayan. No material from this site may be copied, modified, reproduced, republished, 
                     uploaded, transmitted, posted or distributed in any form, whatsoever without prior written permission from Swa-Adhyayan. All rights not expressly
                     granted herein are reserved. Unauthorized use of the materials appearing on this site may violate copyright, trademark and other applicable laws, 
                     and could result in criminal and/or civil penalties.
                  </div>
            </div>
            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      Swa-Adhyayan does not make any warranties, express or implied, including without limitation, those of merchantability
                      and fitness for a particular purpose, with respect to any information, data, statements or products made available on the Site.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      Swa-Adhyayan does not accept any responsibility towards the contents and/or information practices of third party Sites,
                      which have links through Swa-Adhyayan Site. The said links to internal or external website locations are only for the purpose of facilitating your 
                      visit or clarifying your query.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      The Site, and all content, materials, information, software, products and services provided on the Site, are provided on 
                      an ‘as is’ and ‘as available’ basis. Swa-Adhyayan expressly disclaims all warranties of any kind, whether express or implied,
                      including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
                  </div>
            </div>


            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      Swa-Adhyayan shall have no responsibility for any damage to a User's computer system or loss of data that may result 
                      from the download of any content, materials and information from the Site.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      The User agrees to indemnify, defend and hold Swa-Adhyayan harmless from and against all losses, expenses, damages and 
                      costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services 
                      provided on the Site.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      The information contained in the Site has been obtained from sources believed to be reliable. Swa-Adhyayan disclaims 
                      all warranties as to the accuracy, completeness or adequacy of such information.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      The User's right to privacy is of paramount importance to Swa-Adhyayan. Any information provided by the User will not be 
                      shared with any third party. Swa-Adhyayan reserves the right to use the information to provide the User a personalized 
                      online experience.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      The Site provides links to Websites and access to content, products and services from third parties, including users, 
                      advertisers, affiliates and sponsors of the Site. You agree that Swa-Adhyayan is not responsible for the availability of, and 
                      content provided on, third party Websites. The User is requested to peruse the policies posted by other Websites regarding
                      privacy and other topics before use.
                  </div>
            </div>

            <div className={TermsCss.texSection}>
                  {/* <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div> */}
                  <div className={TermsCss.policyText}>
                      Swa-Adhyayan reserves the right in its sole discretion to review, improve, modify or discontinue, temporarily or permanently,
                      the Site or any content or information on the Site with or without notice to User. User agrees that Swa-Adhyayan shall not 
                      be liable to User or any third party for any modification or discontinuance of the Site.
                  </div>
            </div>

          </div>
         </Container>
   </div>
  );
}
export default Terms;
