import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import{Button,  Modal} from 'react-bootstrap'
export const OfflinePopup = ({show, closePopup, showPopup}) => {
    // const [show, setShow] = useState(true);
  
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
  return (
          <>
            <Modal 
              show={show}
              onHide={closePopup}
              onShow={showPopup}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Body>
                <h3>Contact our marketing team in person for free demo.</h3>
                <h3>Mobile No. : 9717998862</h3>
                {/* <h3>Toll free number:</h3> */}
              </Modal.Body>
              
              <Modal.Footer>
                <Button variant="secondary" onClick={closePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
  )
};
