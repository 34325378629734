import productCss from './products.module.css';
import swaIcon from  '../../img/swa.png';
// import android from  '../../img/android.png';
// import ios from  '../../img/ios.png';
// import cbse from  '../../img/cbse.png';
import cuet from '../../img/cuet.png'
import Swar from  '../../img/swar.png';
// import prefect20 from  '../../img/prefect20.png';
// import { CBSEPariksha } from '../../../CBSEPariksha';

import { Container } from 'react-bootstrap';
import { useEffect,} from 'react';
import { Link } from 'react-router-dom';




function Navbarf  () {
    useEffect(()=>{
        window.$("#ProductSlide").owlCarousel({
        items:3,
        itemsDesktop:[1000,2],
        itemsDesktopSmall:[979,2],
        itemsTablet:[768,1],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoPlay:true,
        }); 

    }, 
    
    [])
  return (
    <div className="App ">
     
        <div className={productCss.productsBg}>
            <Container>
            <div className={`container ${productCss.products}`}>
                    <div className={productCss.ourProducts}> OUR FLAGSHIP SERVICES</div>
                <div id="ProductSlide" className={productCss.owl_carousel}>
                    
                        <div className={productCss.testimonial}>
                            <div className={productCss.pic}>
                                <div className={`${productCss.serviceBox} ${productCss.lms}`}>
                                        <div className={productCss.service_icon}> <i className={`fa ${productCss.iconInCricle}`}><img src={swaIcon}/></i> </div>
                                        <div className={productCss.textBox}>
                                            <h3 className={productCss.title}>ADAPTIVE DIGITAL LEARNING <br/>PLATFORM </h3>
                                                <p className={productCss.description}>Swa-Adhyayan is a powerful adaptive and comprehensive E-learning, Assessment...</p>
                                            <div className={productCss.buttonBox}>
                                                <a href="https://swaadhyayan.com/lms/" target="_blank">
                                                    <button className={productCss.aassessBtn}>Access Me</button>
                                                </a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div className={productCss.testimonial}>
                            <div className={productCss.pic}>
                            <div className={`${productCss.serviceBox} ${productCss.swaApp}`}>
                                        <div className={productCss.service_icon}> <i className={`fa ${productCss.iconInCricle}`}><img src={swaIcon}/></i> </div>
                                        <div className={productCss.textBox}>
                                            <h3 className={productCss.title}>SWAADHYAYAN APP</h3>
                                                <p className={productCss.description}>Swa-Adhyayan is the most unique adaptive and comprehensive E-learning, Assessment...</p>
                                            <div className={productCss.buttonBox}>
                                                <a href="https://play.google.com/store/apps/details?id=com.swaadhyayanlms&hl=en" target="_blank">
                                                    <button className={productCss.aassessBtn}>Access Me</button>
                                                </a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    

                        <div className={productCss.testimonial}>
                            <div className={`${productCss.serviceBox} ${productCss.cuet}`}>
                            <div className={productCss.serviceBox}>
                                <div className={productCss.service_icon}><i className={`fa ${productCss.iconInCricle}`}><img src={cuet}/></i></div>
                                    <div className={productCss.textBox}>
                                        <h3 className={productCss.title}> CUET UG 2023 to CUET UG 2024</h3>
                                            <p className={productCss.description}>The CUET UG 2023 to CUET UG 2024, earlier known as Central Universities Common...</p>
                                        <div className={productCss.buttonBox}>
                                            <a href="https://cuet.swaadhyayan.com/" target="_blank">
                                                <button className={productCss.aassessBtn}>Access Me</button>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>


                        {/* <div className={productCss.testimonial}>
                            <div className={`${productCss.serviceBox} ${productCss.blue}`}>
                            <div className={productCss.serviceBox}>
                                <div className={productCss.service_icon}><i className={`fa ${productCss.iconInCricle}`}><img src={cbse}/></i></div>
                                    <div className={productCss.textBox}>
                                        <h3 className={productCss.title}>CBSE PARIKSHA</h3>
                                            <p className={productCss.description}>Designed as per the Time Management Skills, marking scheme and prescribed syllabus by the CBSE.</p>
                                        <div className={productCss.buttonBox}>

                                            <Link to="/CBSEPariksha" target="_blank">
                                                <button className={productCss.aassessBtn}>Access Me</button>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> */}


                        <div className={productCss.testimonial}>
                            <div className={productCss.pic}>
                            <div className={`${productCss.serviceBox} ${productCss.swarApp}`}>
                                    <div className={productCss.service_icon}><i className={`fa ${productCss.iconInCricle}`}><img src={Swar}/></i></div>
                                        <div className={productCss.textBox}>
                                            <h3 className={productCss.title}>SWAR APP</h3>
                                                <p className={productCss.description}>It introduces augmented reality based videos and interactive activities.</p>
                                            <div className={productCss.buttonBox}>
                                                <a href="https://play.google.com/store/apps/details?id=foreverappar.com.foreverappar" target="_blank">
                                                    <button className={productCss.aassessBtn}>Access Me</button>
                                                </a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                </div>

            
                </div>
            </Container>
        </div>
     
    </div>
  );
}

export default Navbarf;
