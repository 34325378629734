import { Container } from 'react-bootstrap';
import TestimonialCss from './testimonial.module.css';
import { useEffect, useState } from 'react';

import data from './AutoherData'


function Testimonial() {

    const [showPop, setpop] = useState(false)

    useEffect(() => {
        window.$("#testimonial-slider").owlCarousel({
            items: 2,
            itemsDesktop: [1000, 2],
            itemsDesktopSmall: [979, 2],
            itemsTablet: [768, 1],
            pagination: false,
            navigation: true,
            navigationText: ["", ""],
            autoPlay: false
        });

    }, []);

    
    const [selectData, setSelectData] = useState()
    function readMore(data) {
        setSelectData(data)
        setpop(true)
    }

    function closeRead() {
        setpop(false)
    }

    return (

        <div className={TestimonialCss.TestimonialBack}>
            <Container className="testiMonialComtainer">
                <div className={TestimonialCss.testimonialHeading}>FROM THE DESK OF AUTHORS AND EDUCATORS</div>
                <div id="testimonial-slider" className={TestimonialCss.owl_carousel}>

                    {
                        data.map((item) => {
                            
                            let newDig = ''
                            if (item.designation.length > 50) {
                                newDig = item.designation.substring(0, 50) + '...'
                            }else{
                                newDig = item.designation
                            }
            

                            return (
                                <>
                                    <div className={TestimonialCss.testimonial}>
                                        <div className={TestimonialCss.description}>
                                            <div className={TestimonialCss.msg}>
                                                {item.msg}
                                            </div>
                                            <div className={TestimonialCss.readMore}><button className='btn btn-primary btn-sm' onClick={() => readMore(item)}>Read More...</button></div>

                                        </div>
                                        <div className={TestimonialCss.authorsDiv}>
                                            <div className={TestimonialCss.pic}>
                                                <img src={item.img} />
                                            </div>
                                            <div className={TestimonialCss.authorName}>
                                                <div className={TestimonialCss.nameAuth}>{item.name}</div>
                                                <div className={TestimonialCss.digAuth}>{newDig}</div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }

                </div>
            </Container>

            {showPop &&
            <div className={TestimonialCss.popBase}>
                <Container className={TestimonialCss.inner}>
                    <div className={TestimonialCss.headerInner}>
                        <div className={TestimonialCss.imgSec}>
                            <div className={TestimonialCss.imgCircle}>
                                <img src={selectData.img} alt="swaadhyayan" />
                            </div>
                        </div>
                        <div className={TestimonialCss.textSec}>
                        <div className={TestimonialCss.selectedName}>{selectData.name}</div>
                        <div className={TestimonialCss.selectDig}>{[selectData.designation]}</div>
                            <div className={TestimonialCss.selectMsg}>{selectData.msg}</div>
                        </div>
                    </div>
                    <div className={TestimonialCss.buttonDiv}
                    onClick={closeRead}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                        
                    </div>
                </Container>
            </div>
            }

        </div>

    );

}

export default Testimonial;
