import ContactUsCss from './contact.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

import { Col, Container, Row,Button } from 'react-bootstrap';
import ContactUsBanner from '../img/contactUs/contactUsBanner.webp';
import ContactUsFormImg from '../img/contactUs/form.webp';
import { Helmet } from 'react-helmet';


function ContactUs() {
  

  return (
    <div className={ContactUsCss.mainContactBg}>

      <Helmet>
        <title>Contact us for Best School Digital Learning Platform - Swa Adhyayan</title>
        <meta name="description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency." />
        <meta property="og:title" content="Contact us for Best School Digital Learning Platform - Swa Adhyayan" /> 
        <meta property="og:description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency"/> 
        <meta property="og:url" content="https://swaadhyayan.com/ContactUs" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/contactUsBanner.e35e7253.webp" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Contact us for Best School Digital Learning Platform - Swa Adhyayan"/>
        <meta name="twitter:description" content="The Online Learning Platform offers interesting and stimulating module to identify the preferred learning style of child and build their progressive proficiency" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/contactUsBanner.e35e7253.webp" />

      </Helmet>

      {/* Banner Section */}
      <div className={ContactUsCss.Banner}>
        <img className={ContactUsCss.banner} src={ContactUsBanner}/>
      </div>
      {/* Banner Section */}

      {/* Second Section */}
      <div className={ContactUsCss.SecontSection}>
        <Container>
          <Row className="justify-content-around">
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <LocationOnIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}> ADDRESS </h3>
                        <p class={ContactUsCss.description}>
                        4583/15, Opp. LIC Building,<br/>  Daryaganj, P.O. Box 7226, New Delhi-110002 
                        </p>
                    </div>
                </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <LocalPhoneIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}>PHONE NUMBER </h3>
                        <p class={ContactUsCss.description}>
                        011 - 43585858,  23285568<br />
                        011 - 23243519
                        </p>
                    </div>
                </div>
            </Col>
            <Col className="col-md-4 col-sm-6 col-12">
                <div class={ContactUsCss.serviceBox}>
                    <div class={ContactUsCss.service_icon}>
                        <EmailIcon className={ContactUsCss.iconInbox}/>
                    </div>
                    <div class={ContactUsCss.service_content}>
                        <h3 class={ContactUsCss.title}> EMAIL ADDRESS </h3>
                        <p class={ContactUsCss.description}>
                        <a href="mailto:info@swaadhyayan.com">info@swaadhyayan.com</a>
                        </p>
                    </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Second Section */}

      {/* Form Section */}
      <div className={ContactUsCss.formBg}>

      
      <Container>
      <div className={ContactUsCss.sendUs}>SEND US A MESSAGE </div>
        <Row>
          <Col className="col-md-6 col-sm-12 col-12">
             <img className={ContactUsCss.formIMGs} src={ContactUsFormImg}/>
          </Col>
          <Col className="col-md-6 col-sm-12 col-12">
            <div className={ContactUsCss.formInputbg}>
            <form method="post" action="">
              <label>Full Name :</label>
              <input type="text" name="FullName" required placeholder=""/>
              <label>Email address :</label>
              <input type="text" name="email" required placeholder=""/>
              <label>Mobile number :</label>
              <input type="number" name="M_number" required placeholder=""/>
              <label>Query :</label>
              <textarea required name="Message"></textarea>
              
              <Button type="submit" variant="primary">Submit</Button>
            </form>
            </div>
          </Col>
        </Row>

      </Container>
      </div>
      {/* Form Section */}

     


   </div>
  );
}

export default ContactUs;
