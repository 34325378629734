import NabbarCss from './navbar.module.css';
import logo from '../../img/logo.png';
import PDFNew from '../../../components/PDF/OnlineCatalog.pdf'
import PDFNew1 from '../../../components/PDF/OfflineCatalog.pdf'
import { useState } from 'react';

import { Container, NavDropdown } from 'react-bootstrap';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
// import {Link} from 'react-scroll'
import getColor from "react-router-dom";
// import { PopNewLunch } from './PopNewLunch';



function NavbarF() {

  const pathName = useLocation().pathname;
  // console.log(pathName);

  function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  
  // Close the dropdown if the user clicks outside of it
  window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }

  function scrollTop(){
    window.reload();
  }
  return (
    <>
      <div className={`sticky-top ${NabbarCss.containera}`}>


        <Navbar className={NabbarCss.navigations} collapseOnSelect expand="lg" bg="white" variant="dark">
          <Container >
            <div className={`col-8 col-md-3 col-sm-5 ${NabbarCss.logoImg}`} href="#home"><Link to="/"><img src={logo} title="Home" /></Link></div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav className={NabbarCss.navLink} collapseOnSelect>
                <Link to="/" className={pathName == '/' ? NabbarCss.clickBtn : null} onClick={scrollTop} >Home</Link>
                <Link to="/aboutUs" className={pathName == '/aboutUs' ? NabbarCss.clickBtn : null} onClick={scrollTop}>About Us</Link>
                {/* <NavDropdown activeClassName="active" title="Our Products" id="Ourproduct-dropdown" noCaret={true}>
            <NavDropdown.Item ><Link to="/OurProduct/1" className={pathName=='/OurProduct/1'?NabbarCss.clickBtn:null}>Online Product</Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/OurProduct/2" className={pathName=='/OurProduc/2'?NabbarCss.clickBtn:null}>Offline Product</Link></NavDropdown.Item>
            
            </NavDropdown> */}
                <Link to="/services" className={pathName == '/services' ? NabbarCss.clickBtn : null} onClick={scrollTop}>Our Services</Link>
                {/* <Link to="/Event" className={pathName=='/Event'?NabbarCss.clickBtn:null} onClick = {scrollTop}>Event</Link> */}
                <Link to="/event" className={pathName == '/event' ? NabbarCss.clickBtn : null} onClick={scrollTop} >News & Event</Link>
                <Link to="/blog" className={pathName == '/blog' ? NabbarCss.clickBtn : null} onClick={scrollTop}>Blog</Link>
                <Link to="/contactUs" className={pathName == '/contactUs' ? NabbarCss.clickBtn : null} onClick={scrollTop}>Contact Us</Link>
                <Link to="/career" className={pathName == '/carrer' ? NabbarCss.clickBtn : null} onClick={scrollTop}>Career</Link>
                {/* <NavDropdown  className="subhash" title="Login" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://swaadhyayan.com/school" target="_blank">For Registered Schools</NavDropdown.Item>
              <NavDropdown.Item href="https://swaadhyayan.com/lms" target="_blank">Others</NavDropdown.Item>
          </NavDropdown> */}
                <div className="dropdown">
                  <div onClick={myFunction} className="dropbtn">Login</div>
                  <div id="myDropdown" className="dropdown-content">
                    <a href="https://swaadhyayan.com/school1/" target="_blank">For Registered Schools</a>
                    <a  href="https://swaadhyayan.com/lmsv2/" target="_blank">Others</a>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>

        </Navbar>




      </div>
    </>
  );
}

export default NavbarF;
