import React from 'react';
import {Container} from 'react-bootstrap';
import CPCss from './CBSEP.Module.css'
export const CBSEPariksha = () => {
  return (
      <Container>
    <div className={CPCss.borderdiv}>
    <div className={CPCss.heading}><h2>Content Required </h2></div>
    </div>
    </Container>
  )
}
export default CBSEPariksha;