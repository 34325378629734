import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import EventCss from './Event.module.css'
import BannerImg from '../img/EVENT/eventBanner.webp'
import Carousel from 'react-bootstrap/Carousel';
// news 1//
import news1a from '../img/EVENT/Demo1/news1.webp'
import news1b from '../img/EVENT/Demo1/news2.webp'
import news1c from '../img/EVENT/Demo1/news3.webp'
import news1d from '../img/EVENT/Demo1/news4.webp'
import news1e from '../img/EVENT/Demo1/news5.webp'
import news1f from '../img/EVENT/Demo1/news6.webp'
// news 1//

//  news 2 //
import news2a from '../img/EVENT/Demo2/news (1).webp'
import news2b from '../img/EVENT/Demo2/news (2).webp'
import news2c from '../img/EVENT/Demo2/news (3).webp'
import news2d from '../img/EVENT/Demo2/news (4).webp'
//  news 2 //

//  news 3 //
import news3a from '../img/EVENT/Demo3/news (1).webp'
import news3b from '../img/EVENT/Demo3/news (2).webp'
import news3c from '../img/EVENT/Demo3/news (3).webp'
import news3d from '../img/EVENT/Demo3/news (4).webp'
//  news 3 //


//  news 4 //
import news4a from '../img/EVENT/Demo4/news1.webp'
import news4b from '../img/EVENT/Demo4/news2.webp'
import news4c from '../img/EVENT/Demo4/news3.webp'
import news4d from '../img/EVENT/Demo4/news4.webp'
import news4e from '../img/EVENT/Demo4/news4.webp'
//  news 4 //

//  news 5 //
import news5a from '../img/EVENT/Demo5/news (1).webp'
import news5b from '../img/EVENT/Demo5/news (2).webp'
import news5c from '../img/EVENT/Demo5/news (3).webp'
import news5d from '../img/EVENT/Demo5/news (5).webp'
import news5e from '../img/EVENT/Demo5/news (4).webp'
//  news 5 //

import demoIcon from '../img/EVENT/demoIcon.png'

import Video from '../home/vdogallery/Vdogallery'
import { Helmet } from 'react-helmet';




const newsData = [
  {
    id: 1,
    heading: 'A Demo Session for ECCE and Swa-Adhyayan was conducted at Divine English School, Bengaluru by Ms. Shilpi for the teachers.',
    date: '23 January, 2023',
    corImg: [news1a, news1b, news1c, news1d, news1e, news1f],
    discription:''
  },
  {
    id: 2,
    heading: 'Session: Once Demonstrated, Always Remembered!.',
    date: '21 January, 2023',
    corImg: [news2a, news2b, news2c, news2d,],
    discription:'A Demo Session of Swa-Adhyayan was conducted at Xion International Convent School, Gurugram by our zealous Team members.'
  },
  {
    id: 3,
    heading: 'Swa-Adhyayan Demo Session was conducted at Delhi World Public School, Orai attended by teachers and the Management',
    date: '15 January, 2023',
    corImg: [news3a, news3b, news3c, news3d],
    discription:''
  },
  {
    id: 4,
    heading: 'Demo Session of Swa-Adhyayan conducted by Mr. Devashish Srivastava and Mr. Sandeep Ahuja at Manav Mangal Group of Schools, Chandigarh.',
    date: '10 January, 2023',
    corImg: [news4a, news4b, news4c, news4d, news4e],
    discription:'The Learning Management System (LMS) is an adaptive and comprehensive E-learning, assessment and sharing platform for students.  The unique LMS has been designed to plan, implement and assess the specific learning process of students by providing a way to teachers to create and deliver contents enabling them to assess the learners’ performances.'
  },
  {
    id: 5,
    heading: 'ECCE Demonstration',
    date: '08 January, 2023',
    corImg: [news5a, news5b, news5c, news5d, news5e],
    discription:`The Demo Session for Early Childhood Care and Education was conducted at R P Oxford Public School, Rajasthan to reiterate the commitment to invest in optimal early childhood development for promoting active learning capacity of all children of pre-school age.<br/> The unique feature of the programme capitalises on a period of rich brain development for children to achieve their full potential. SIGN UP HERE FOR FREE DEMO https://swaadhyayan.com/lms//demo/login #DemoSession #ECCE #Demonstration #EarlyChildhoodCareandEducation #RPOxfordPublicSchool #ChildhoodDevelopment #SwaAdhyayan #LearningManagementSystem`
  },

];



const Event = () => {

  
  
  const [selectedData, setSelectedData] = useState(newsData[0]);

  const [showNews, setShowNews] = useState({news:true, video:false, type:''})
  
  
  function setSelectedValue(val, id) {
    setSelectedData(val)
  }

  function openNews(val){
    if(val == 'news'){
      setShowNews((prev)=>{
        return{...prev, news:true, video:false}
      })
    }else if(val == 'video'){
      setShowNews((prev)=>{
        return{...prev, video:true, news:false}
      })
    }
  }

  
  return (
    <div>

      <Helmet>
        <title>News and Event – Swa-Adhyayan | Best Learning Management System based on Multiple Intelligences</title>
        <meta name="description" content="News and Events We at Swa-Adhyayan organise time to time events like Workshops, Demo Sessions and Seminars for schools, teachers, principle, directors, parents as well as students." />

        <meta property="og:title" content="News and Event – Swa-Adhyayan | Best Learning Management System based on Multiple Intelligences" /> 
        <meta property="og:description" content="News and Events We at Swa-Adhyayan organise time to time events like Workshops, Demo Sessions and Seminars for schools, teachers, principle, directors, parents as well as students"/> 
        <meta property="og:url" content="https://swaadhyayan.com/Event" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/news%20(1).bd001e4e.webp" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="News and Event – Swa-Adhyayan | Best Learning Management System based on Multiple Intelligences"/>
        <meta name="twitter:description" content="News and Events We at Swa-Adhyayan organise time to time events like Workshops, Demo Sessions and Seminars for schools, teachers, principle, directors, parents as well as students" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/news%20(1).bd001e4e.webp" />

      </Helmet>
    
      <div className="py-5 bg-light">
        <div className='container bg-white p-0'>
          <div className={EventCss.menuBtn}>
            <button className='btn btn-primary mx-2' onClick={()=>openNews('news')}>News</button>
            <button className='btn btn-primary' onClick = {()=>openNews('video')}>Video</button>
          </div>

          {showNews.video &&
            <Video/>
          }
          


          
          {showNews.news &&

            <div className='row gx-5'>
              <div className='col-md-8 col-sm-12 col-12 p-0'>
                <div className="">
                  <Carousel className={EventCss.carouselContainer}>
                    {selectedData.corImg.map((item, index)=>{
            
                      return(
                          <Carousel.Item className={EventCss.carouselItem}>
                            <img src={item} />
                          </Carousel.Item>
                      )
                    })}

                  </Carousel>
                  <div className={EventCss.newsHead}>{selectedData.heading}</div>
                  <div className={EventCss.newsdate}>{selectedData.date}</div>
                  <div className={EventCss.newsHeadDis}>{selectedData.discription}</div>
                </div>


              </div>



              <div className='col-md-4 col-sm-12 col-12 py-2'>

                {newsData.map((item, index) => {

                  let shortHead = ''
                  if (item.heading.length > 30) {
                    shortHead = item.heading.substring(0, 27) + '...'
                  }else{
                    shortHead = item.heading
                  }
                  return (
                    <div className={EventCss.newsListDiv} onClick={() => setSelectedValue(item, item.id)} key={item.id}>
                      <div className={EventCss.imgThumnail}>
                        <img src={item.corImg[0]} alt="" />
                      </div>
                      <div className={EventCss.listTextDiv}>
                        <div className={EventCss.listHead}>{shortHead}</div>
                        <div className={EventCss.listHeadDisc}>{item.date}</div>
                      </div>
                    </div>
                  )
                })}



              </div>
            </div>
          }
          
          
        </div>
      </div>
    </div>
  )
}

export default Event