import DisclaimerCss from './disclaimer.module.css';
import Banner from '../img/disclaimer/banner.webp';

import { Container, Row } from 'react-bootstrap';


function Terms() {

  return (
    <div className={DisclaimerCss.termsBG}>
        <img className={DisclaimerCss.banner} src={Banner}/>
        <Container>
        <div className={DisclaimerCss.textContant}>
        
          <div className={DisclaimerCss.runingText}>
            The information contained in swaadhyayan.com is for general information purposes only. While swaadhyayan.com makes the effort to 
            keep the information upgraded and correct, we make no representations or warranties of any kind, express or implied, about the completeness, 
            accuracy, reliability, suitability or availability with respect to the information, products, services, or related graphics contained in this 
            website, for any purpose. Any reliance you place on such information is strictly at your own risk. Swaadhyayan.com takes no responsibility for, 
            and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
          </div>

          




         



          </div>
         </Container>
   </div>
  );
}
export default Terms;
