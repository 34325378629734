import footerCss from './footer.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Sw_logo from "../../img/swa_logo.png";
import iso from "../../img/lmsCertificate.webp";
import cmmi from "../../img/IsoCertificate.webp"
import playStore from '../../img/playStory.png';
import catalogue from '../../img/Swa-Adhyayan Catalogue.pdf'
import appStore from '../../img/appStore.png'

function Footer() {

  function scrollTop(){
    window.scrollTo({top:0, behavior: 'smooth'});
  }

  return (
    <div className="App bounceIn animated">
      <footer className={footerCss.footer}>
        <Container>
           <Row>
             <Col className={`col-md-5 col-sm-12 col-12 ${footerCss.borderline}`}>
                  <div className={footerCss.headingText}>
                      <div className="">
                        <img src={Sw_logo} alt="" />
                      </div>
                      <div className={footerCss.head}>ABOUT SWA-ADHYAYAN</div>
                    </div>
                  <div className={footerCss.swaAboutText}>
                  Swa-Adhyayan is a powerful, an adaptive and comprehensive E-learning, Assessment and Sharing platform for students to identify their learning styles and build their progressive proficiency. Activities and assessments on this Digital Platform are mapped to the latest curriculum which make the learning process more effective and adaptive. The assessments for kindergarten to grade 8 generate multiple reports and the progress cards of the children, depicting various parameters. It enables all the users to share useful resources and learning outcomes which are beneficial for interaction between the schools, teachers, parents and students.
                  </div>
             </Col>
             <Col className={`col-md-7 col-sm-12 col-12 ${footerCss.borderline2}`}>
             <form className={footerCss.example} action="/action_page.php">
                    <input type="text" placeholder="Enter your Email.." name="search"/>
                    <button type="submit">Subscribe</button>
                </form>
                <Row className='g-0'>
                    <Col className="col-md-4 col-sm-4 col-12">
                       <div className={footerCss.headingText}>QUICK LINKS</div>
                       <div className={footerCss.footerNav}>
                         <a onClick = {scrollTop} href="#"><Link to="/aboutUs" >About Us</Link></a>
                         <a onClick = {scrollTop} href="#"><Link to="/services">Our Services</Link></a>
                         <a onClick = {scrollTop} href="#"><Link to="/contactUs">Contact us</Link></a>
                         <a onClick = {scrollTop} href="#"><Link to="/career">Career</Link></a>
                         <a href={catalogue} download='Swa-Adhyayan Catalogue'>Catalogue</a>
                       </div>
                    </Col>
                    <Col className="col-md-4 col-sm-4 col-12">
                       <div className={footerCss.headingText}>POLICY</div>
                       <div className={footerCss.footerNav}>
                        <Link to="/privacyPolicy"><a onClick = {scrollTop} href="#">Privacy Policy</a></Link>
                        <Link to="/terms-and-conditions"><a onClick = {scrollTop} href="#">Terms & Conditions</a></Link>
                        <Link to="/disclaimer"><a onClick = {scrollTop} href="#">Disclaimer</a></Link>
                      </div>
                    </Col>
                    <Col className="col-md-4 col-sm-4 col-12">
                       <div className={footerCss.headingText}>OUR CERTIFICATIONS</div>
                       <Row className='g-0'>
                        <Col className='col-md-6 col-12 '>
                          <div className='p-2 text-center'>
                            <img className={footerCss.cerImg}  src={iso} alt="" />
                          </div>
                          <div className={`text-white text-center f-14 ${footerCss.certificate}`}>ISO/IEC 27001:2013</div>
                        </Col>
                        <Col className='col-md-6 col-12'>
                          <div className='p-2 text-center'>
                            <img className={footerCss.cerImg} src={cmmi} alt="" />
                          </div>
                          <div className={`text-white text-center ${footerCss.certificate}`}>CMMI Maturity Level 3</div>
                        </Col>
                        
                       </Row>
                    </Col>
                </Row>
                <div className={footerCss.playstoreContainer}>
                  <div className={footerCss.playstoreText}>Try Our App</div>
                  <a href="https://play.google.com/store/apps/details?id=com.swaadhyayanlms&hl=en" target='_blank'>
                      <div className={footerCss.playstoreimg}>
                        <img className={footerCss.playimg} src={playStore} alt="" />
                      </div>
                  </a>
                  <a href="https://apps.apple.com/us/app/swaadhyayanlms/id6450884259" target='_blank'>
                      <div className={footerCss.playstoreimg}>
                        <img className={footerCss.playimg} src={appStore} alt="" />
                      </div>
                  </a>
                </div>

             </Col>
           </Row>
        </Container>
        
       
      </footer>
      <div className={footerCss.copyRight}>Copyright © 2021 Swa-Adhyayan All Right Reserved.</div>
    </div>
  );
}

export default Footer;
