import privacypolicyCss from './privacypolicy.module.css';
import Banner from '../img/pri/Privacy-Policy.webp';
import AlbumIcon from '@mui/icons-material/Album';

import { Container, Row } from 'react-bootstrap';


function privacy() {

  return (
    <div className={privacypolicyCss.privacypolicyBG}>
        <img className={privacypolicyCss.banner} src={Banner}/>

      <div className={privacypolicyCss.bgText}>
            <Container>
              <div className={privacypolicyCss.mainContainer}>
                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Cancellation & Refund Policy</div>
                  <div className={privacypolicyCss.policyText}>
                      As of now, we do not provide any option for cancellation of products and/or services you have
                      purchased or subscribed. Once a product/service has been purchased by you, we cannot provide any
                      refund, either totally or partially. We suggest that you first go through the demos and/or free to use
                      contents/products/services before you subscribe to or purchase from swaadhyayan.com.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Pricing Policy</div>
                  <div className={privacypolicyCss.policyText}>
                      All prices are subject to change without any notice. Our Pricing Policy includes all the taxes and norms
                      that are applicable as per the government policies.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Advertisement</div>
                  <div className={privacypolicyCss.policyText}>
                        The site may at its discretion display advertisements. The user’s correspondence or business dealings
                        with, or participation in promotions of, advertisers found on or through swaadhyayan.com service,
                        including payment and delivery of related goods or services, and any other terms, conditions, warranties
                        or representations associated with such dealings, are solely between the user and such advertiser. The
                        user agrees that swaadhyayan.com shall not be responsible or liable for any loss or damage of any sort
                        incurred as a result of any such dealings or as a result of the presence of such advertisers on the
                        swaadhyayan.com service.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Privacy Statement</div>
                  <div className={privacypolicyCss.policyText}>
                         This Privacy Statement is designed to advise you about the type of information that swaadhyayan.com
                          collects and the purposes, for which this information is being processed, used, maintained and
                          disclosed. Swaadhyayan.com treats personal data which it receives through its websites; portals and any
                          other means with due care and is dedicated to safeguard any personal data it receives. This Privacy
                          Policy helps to ensure that swaadhyayan.com complies with applicable laws in the countries in which it
                          operates. We, at swaadhyayan.com, take your privacy seriously, and will only use your personal
                          information for the purposes permitted by law or specified in this Privacy Policy. Please read our Privacy
                          Policy, and let us know if you have any questions.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                          Swaadhyayan.com is committed to protect your privacy whenever you buy our products.
                          Swaadhyayan.com recognizes its responsibility to keep all personal information confidential at all times.
                          Any information that swaadhyayan.com acquires in connection with such transactions is kept
                          confidential. However, please note that the responsibility of swaadhyayan.com is limited to the
                          protection of information that is obtained by swaadhyayan.com only.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Personal Data</div>
                  <div className={privacypolicyCss.policyText}>
                  Personal data refers to any information that tells us something about you or that we can link to you. We will only collect and process your personal data where we have lawful bases. Lawful bases on which we would process your personal information includes obtaining explicit consent from you for processing your personal information or processing for ‘legitimate interests’ where processing is necessary by us to provide you with the required services. However, please note that your personal data may be shared with legal authorities, if required by law. 
                  </div>
                  <div className={privacypolicyCss.policyText}>
                  Swaadhyayan.com uses a variety of technologies and procedures to help protect personal information from unauthorised access, use or disclosure.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                  We may request access or permission to certain features from your mobile device, including bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, storage and other features. If you wish to change our access or permission, you may do so in your device’s settings. 
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Children’s Privacy Policy</div>
                  <div className={privacypolicyCss.policyText}>
                  We do not aim any of our products or services directly at children under the age of 6, and we do not knowingly collect personal information about children under the age of 6. We collect some basic information like First Name, Last Name, Date of Birth, Class, Section, School Name, Parents Information like Father's Name, Mother's Name, etc., from school desk or parents. We do not collect any information through our website directly. We do not connect with the children directly. If you think that your child has provided this type of information on our website, we strongly encourage you to contact us immediately and we will do our best to promptly remove such information from our records. Swa-Adhyayan LMS app is used by the children under the guidance of their teachers or parents. Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activities.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Third Party Privacy Policies</div>
                  <div className={privacypolicyCss.policyText}>
                  Swa-Adhyayan family privacy policy does not apply to other advertisers or websites. So, we are requesting you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                  While using our website and app, you may be linked or directed to other websites hosted by third parties.  For example, our website includes some Swa-Adhyayan pages created on Facebook and YouTube which include educational videos, knowledge enhancement content, educational e-books, etc. This privacy policy does not describe the information collection and disclosure practices of such third parties. We do not control and are not responsible for the privacy practices and policies of such third parties or the content of these third party websites. Your interactions with these features are governed by the privacy policies of those third parties. We encourage you to review the privacy policies of such third parties.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Google Analytics</div>
                  <div className={privacypolicyCss.policyText}>
                          Swaadhyayan.com adopts Google Analytics software to carry out Site usage analytics. Google Analytics
                          gathers anonymous info from visitors to assist swaadhyayan.com in tracing Site usage and citations from
                          other sites. The tracked data are employed principally to enhance the website experience for our
                          visitors, however, we may also use the tracked data to help us in improving marketing strategies for our
                          Site.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                           Whatever data is gathered and administered by Google Analytics chiefly comprises the user’s IP address,
                          network and geographical location. All such information is directly collected from the user by Google
                          Analytics, simply by installing a cookie on the user’s computer. Swaadhyayan.com does not share any
                          information it gathers from Google, and Google Analytics does not save any privately identified
                          information. Correspondingly, we cannot operate any info attained by Google Analytics to directly
                          contact a visitor.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Cookies</div>
                  <div className={privacypolicyCss.policyText}>
                          Cookie is a small text file of letters and numbers that is stored by the browser on your computer when
                          you visit certain websites. A cookie allows a website to distinguish your computer from other users’
                          computers. Cookies do not contain personal data unless you have already given this information to the
                          Website. You may delete or block the cookies, if that is what you choose to do. Note that this may make
                          it less convenient for you to use our website. Swaadhyayan.com website may contain links to other
                          websites of your interest. Please note that once the Visitor opts to link to other websites,
                          swaadhyayan.com shall not be responsible for protection of further disclosure of information and this
                          Privacy Policy/statement cease to be applicable for such disclosure of information. The Visitor may
                          therefore, like to exercise caution and look at the privacy statement applicable to those websites.
                          Similarly your financial data like Account Number, Debit/Credit Card Number, etc., provided by you to
                          pay us through third party payment gateway is protected by the service provider only. Remember,
                          swaadhyayan.com never asks for any such information from you directly and is not in any case,
                          responsible for the misuse of the same.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                          Swaadhyayan.com website may be available on various other third party websites. However,
                          swaadhyayan.com shall not be responsible to the contents and material of such other websites. If any
                          person provides its information on other websites, where the link of swaadhyayan.com or
                          swaadhyayan.com logo is available, swaadhyayan.com shall not be responsible to any act or omission
                          committed by third party websites including but not limited to data theft or misuse of information of
                          such a person.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                        Swaadhyayan.com reserves its right to amend the present Privacy Policy at any time and will place such
                          amended Privacy Policy, if any, on its website. This Privacy Policy is neither intended to and nor does it
                          create any contractual rights whatsoever or any other legal rights, nor does it create any obligations on
                          swaadhyayan.com in respect of any other party or on behalf of any party. By accessing this website and
                          any of its pages, you are agreeing to the terms set out above.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Security Policy</div>
                  <div className={privacypolicyCss.policyText}>
                          The user might receive a Password and User ID upon completing the service&#39;s registration process. The
                          users are expected to maintain the confidentiality of the password and account, and shall be held solely responsible for all acts and omissions of Password or User ID. Swaadhyayan.com cannot and will not be liable for any loss or damage arising from the user’s failure to comply with the terms and conditions
                          of this agreement.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Termination</div>
                  <div className={privacypolicyCss.policyText}>
                          If users commit any breach of trust under these terms and conditions like disclosing your User ID and
                          Password to another who is not authorized, swaadhyayan.com have right to terminate or discontinue
                          services immediately without any notice.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Arbitration</div>
                  <div className={privacypolicyCss.policyText}>
                          Any dispute, difference or question arising out of these terms and conditions shall be settled amicably
                          between the user and officer who is appointed by swaadhyayan.com, failing which, the same shall be
                          referred to arbitrator under the Arbitration and Conciliation Act 1996, and the venue of the arbitration
                          shall be in Delhi only.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Court of Jurisdiction</div>
                  <div className={privacypolicyCss.policyText}>
                        Swaadhyayan.com controls and operates this website from its headquarters in Delhi, India. You agree
                        that any dispute shall be subject to the exclusive jurisdiction of Courts at Delhi only.
                  </div>
                </div>

                <div className={privacypolicyCss.texSection}>
                  <div className={privacypolicyCss.policyHead}>Content and Liability Disclaimer</div>
                  <div className={privacypolicyCss.policyText}>
                        This disclaimer of liability applies to any damage or injury caused by any failure of performance, error,
                        omission, interruption, deletion, defect, delay in operation or transmission, computer virus,
                        communication line failure, theft or destruction or unauthorized access to, alteration of, or use of
                        record, whether for breach of contract, tortuous behavior, negligence, or under any other cause of
                        action.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                        The documents and graphics on this website may include technical inaccuracies or typographical errors.
                        Changes are periodically updated to the information herein. Swaadhyayan.com may make
                        improvements and/or changes herein at any time. Swaadhyayan.com makes no representations about
                        the accuracy of the information contained in the documents and graphics on this website for any
                        purpose. All documents and graphics are provided ‘as is’. Swaadhyayan.com hereby disclaims all
                        warranties and conditions with regard to this information, including all implied warranties and
                        conditions of merchantability, fitness for any particular purpose, title and non-infringement. In no event,
                        Swaadhyayan.com and/or its licensor/supplier shall be liable to any party for any direct, indirect,
                        special or other consequential damages for any use of the sites, the information, or on any other
                        hyperlinked website, including, without limitation, any lost profits, business interruption, loss of
                        programs or other data on your information handling system or otherwise, even if Swaadhyayan.com is
                        expressly advised of the possibility of such damages.
                  </div>
                  <div className={privacypolicyCss.policyText}>
                        Use of this website is at your own risk, and the swaadhyayan.com will not be held liable for any error or
                        omission contained in this website. In no event, shall swaadhyayan.com be liable for any special, indirect
                        or consequential damages, or any damage whatsoever, resulting from the loss of use, data or profits
                        whether contract, negligence or any tort action arising out of, or in connection with, the use or
                        performance of the information available from swaadhyayan.com.
                  </div>
                </div>
              </div>


            </Container>
          </div>
   </div>
  );
}
export default privacy;
