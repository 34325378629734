import headerCss from './header.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoLogoFacebook, IoLogoTwitter,IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube,IoIosCall,IoIosMail } from 'react-icons/io';
import { FaSquareXTwitter } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';


function Header() {
  return (
    <div className="App  animate__animated animate__fadeInDown">
      <header className={headerCss.header}>
        <Container>
            <Row>
                <Col className={headerCss.colClass}>
                <span className={headerCss.headerCol}>
                        <a href=" https://www.facebook.com/swaadhyayanadaptivedigitalplatform/" target="_blank"><span className={headerCss.bgIcons}><IoLogoFacebook className={headerCss.iconsInheader}/></span></a>
                        <a href="https://twitter.com/swaadhyayan/" target="_blank"><span className={headerCss.bgIcons}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"  className={headerCss.iconsInheader}>
                        <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"></path></svg>
                        </span></a>
                        <a href="https://www.instagram.com/_swaadhyayan/" target="_blank"><span className={headerCss.bgIcons}><IoLogoInstagram className={headerCss.iconsInheader}/></span></a>
                        <a href="https://www.linkedin.com/company/swaadhyayan/" target="_blank"><span className={headerCss.bgIcons}><IoLogoLinkedin className={headerCss.iconsInheader}/></span></a>
                        <a href="https://www.youtube.com/channel/UCdboYQZwOQvTUeehZ0haNyQ" target="_blank"><span className={headerCss.bgIcons}><IoLogoYoutube className={headerCss.iconsInheader}/></span></a>
                </span>
                <span className={headerCss.headerCol}>
                       <a href="tel:011-43585858"><span className={headerCss.Number}><IoIosCall/>011-43585858</span></a>
                </span>
                <span className={headerCss.headerCol}>
                <a href="mailto:info@swaadhyayan.com"><span className={headerCss.email}><IoIosMail/> info@swaadhyayan.com</span></a>
                </span>
                </Col>
            </Row>
        </Container>
        
       
      </header>
    </div>
  );
}

export default Header;
