import { Col, Container, Row } from 'react-bootstrap';
import NewLunchingCss from './NewLunching.module.css';
import NewLunchingImg from '../img/NewLunching.png';
import { Link } from "react-router-dom";




function NewLunching() {
  return (
    <div>
      <Container>
          <div className={NewLunchingCss.NewLunchingline}></div>
        <Row>
          <Col className="col-12 col-md-6 col-sm-6">
            <img className={NewLunchingCss.imgReady} src={NewLunchingImg}/>
          </Col>
          <Col className="col-12 col-md-6 col-sm-6">
            <div className={NewLunchingCss.startYour}>LMS for Classes 9 to 12 and CUCET.
            </div>
            <div className={NewLunchingCss.next}>Vast learning solutions through LMS. Practice questions for Entrance tests in under-graduate courses. 
            </div>
            {/* <button className={NewLunchingCss.buttonDivin}><a href="https://swaadhyayan.com/lms/demo/login" target="_blank">Get Started</a></button>
            <button className={NewLunchingCss.buttonDivin}><Link to="/ContactUs">Contact Us</Link></button> */}

          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default NewLunching;
