import React from 'react';
import { Link } from 'react-router-dom'
import errorImg from '../components/img/404.png'
// import errorImg from '../components/img/errorpage.webp'



const Error404 = () => {
  return (
    <div className="">
        <div className="container" style={{padding:"40px 0"}}>
            <div className="imgDivNew text-center" >
                <img src={errorImg} alt="" />
            </div>
            <div className="errormsg">The requested page was not found.</div>
            <div className="backBtn">
            <Link to="/"> <button className="btn btn-primary btn-lg btn-sm">Back to Home Page </button></Link>
            </div>
        </div>
    </div>
  )
}

export default Error404
