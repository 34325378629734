
import { TabTitle } from '../../utils/GeneralFunction';
import AboutUs from './aboutus/Aboutswa';
import OurfounderC from './ourfounder/Founder';
import { Helmet } from 'react-helmet';


function Aboutswa() {
  

  return (
    <div>
      <Helmet>
        <title>Best Educational E-learning Platform | Swa Adhyayan LMS</title>
        <meta name="description" content="Swa Adhyayan LMS, Best Digital learning platform complimented with by learning resources as Text Books, Workbooks & TRM etc. Online Learning platform for school promotes learning." />

        <meta property="og:title" content="Best Educational E-learning Platform | Swa Adhyayan LMS" /> 
        <meta property="og:description" content="Swa Adhyayan LMS, Best Digital learning platform complimented with by learning resources as Text Books, Workbooks & TRM etc. Online Learning platform for school promotes learning"/> 
        <meta property="og:url" content="https://swaadhyayan.com/AboutUs" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/aboutUs.9246961f.png" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Best Educational E-learning Platform | Swa Adhyayan LMS"/>
        <meta name="twitter:description" content="Swa Adhyayan LMS, Best Digital learning platform complimented with by learning resources as Text Books, Workbooks & TRM etc. Online Learning platform for school promotes learning" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/aboutUs.9246961f.png" />
      </Helmet>

      <AboutUs/>
      <div className="" data-aos="zoom-in"  data-aos-duration="1500">
        <OurfounderC/>
      </div>
     
   </div>
  );
}

export default Aboutswa;
