import React from 'react'
import OnlineOffCss from "./OnlineOffring.module.css"
// import AdjustIcon from '@mui/icons-material/Adjust';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/Lightbulb';




const OnlineOffring = () => {
  return (
    <saction>
        <div className="container my-5">
            <div className={`${OnlineOffCss.onlineOffrng_header}`}>
                <h2>Our Online Services</h2>
            </div>
                <div className={OnlineOffCss.subContainer1} data-aos="zoom-out-up" data-aos-duration="1500">
                    <div className={OnlineOffCss.Content}>
                        <div className="fs-4 fw-700">SEPT</div>
                        <div className=""> [Swa-Adhyayan Entrant’s Profiling Test]</div>
                        <div className={OnlineOffCss.subHead}>The Gateway to Login | Participate in Swa-Adhyayan's Unified Learning Management System</div>
                        <p>The Adaptive Digital algorithm starts with a pre-assessment test aimed at judging the current level of skills and knowledge of a student. This is followed by the display of one of the available Remedial Lessons or by moving the student to the Main Tests.</p>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200"><ChevronRightIcon className={OnlineOffCss.iconList}/> Academic Proficiency Profile</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400"><ChevronRightIcon className={OnlineOffCss.iconList}/>Learning Style </div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600"><ChevronRightIcon className={OnlineOffCss.iconList}/>Multiple Intelligences</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800"><ChevronRightIcon className={OnlineOffCss.iconList}/>Brain Dominance</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1000"><ChevronRightIcon className={OnlineOffCss.iconList}/>Knowing Me</div>

                    </div>
                    <div className={OnlineOffCss.septBgImg}  data-aos="zoom-in" data-aos-duration="1000"></div>
                </div>
        </div>


        <div className="container my-5" data-aos="zoom-out-up" data-aos-duration="1500">
                <div className={OnlineOffCss.subContainer2}>
                    <div className={OnlineOffCss.swaLearningBgImg} data-aos="zoom-in" data-aos-duration="1000"></div>
                    <div className={OnlineOffCss.Content2}>
                        <div className="fs-4 fw-700">Swa-learning</div>
                        <div className="pt-3"> Swa-Adhyayan means Self-learning!</div>
                        <p>It is the most comprehensive and progressive way to learn anywhere you want.
                    The biggest interactive learning curriculum with high quality contents in the world of K-8 education comprises 10000+ practice questions. It is suitable for classroom use as well as at home for self-study and practice.</p>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200"><ChevronRightIcon className={OnlineOffCss.iconList}/>Learning by Knowledge Scoop</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400"><ChevronRightIcon className={OnlineOffCss.iconList}/>Learning by Gamification </div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600"><ChevronRightIcon className={OnlineOffCss.iconList}/>CBSE SAFAL & Other Competitive Exams</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800"><ChevronRightIcon className={OnlineOffCss.iconList}/>NCERT Kit</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1000"><ChevronRightIcon className={OnlineOffCss.iconList}/>1500+ Practice Questions per subject per class</div>

                    </div>
                    
                </div>
        </div>

        <div className="container" data-aos="zoom-out-up" data-aos-duration="1500">
            <div className={OnlineOffCss.subContainer1}>
                <div className={OnlineOffCss.Content}>
                    <div className="fs-4 fw-700">Swa-assessment</div>
                    <p>Our evaluation system is 'Inclusive and Progressive' in a true manner for the evaluation of the child. The parameters are based on Multiple Intelligences fuelled by school curriculum (as per the guidelines of CBSE and all other boards).</p>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200"><ChevronRightIcon className={OnlineOffCss.iconList}/>10000+ Assessment Questions</div>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400"><ChevronRightIcon className={OnlineOffCss.iconList}/>Assessment Generator</div>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600"><ChevronRightIcon className={OnlineOffCss.iconList}/>Auto Assessment Generator</div>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800"><ChevronRightIcon className={OnlineOffCss.iconList}/>Add Questions Manually</div>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1000"><ChevronRightIcon className={OnlineOffCss.iconList}/>Assessment Status</div>

                </div>
                <div className={OnlineOffCss.assessBgImg}  data-aos="zoom-in" data-aos-duration="1000"></div>
            </div>
        </div>




       <div className="container my-5" data-aos="zoom-out-up" data-aos-duration="1500">
                <div className={OnlineOffCss.subContainer2}>
                    <div className={OnlineOffCss.swaSharingBgImg}  data-aos="zoom-in" data-aos-duration="1000"></div>
                    <div className={OnlineOffCss.Content2}>
                        <div className="fs-4 fw-700">Swa-sharing</div>
                        <p>It empowers teachers, students, parents and the schools to share useful resources and learning
                    outcomes. It also enables open forum for interaction between the school and the parents.</p>
                        <div className={OnlineOffCss.subHead}>Exclusive features of our sharing platform are:</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200"><ChevronRightIcon className={OnlineOffCss.iconList}/>Audio, Video, Images, etc.</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400"><ChevronRightIcon className={OnlineOffCss.iconList}/>Webcast, To Do list, Opinion poll </div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600"><ChevronRightIcon className={OnlineOffCss.iconList}/>Create event, Learning wall</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800"><ChevronRightIcon className={OnlineOffCss.iconList}/>Personal Chat messenger</div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1000"><ChevronRightIcon className={OnlineOffCss.iconList}/>Personalised mail box</div>

                    </div>
                    
                </div>
        </div>

        <div className="container my-5" data-aos="zoom-out-up" data-aos-duration="1500">
            <div className={OnlineOffCss.subContainer1}>
                <div className={OnlineOffCss.Content}>
                    <div className="fs-4 fw-700">Homework</div>
                    <p> It helps teachers to manage and create homework for students. Teachers can keep track of total homework given per chapter or in a term and homework submitted by students.</p>
                    <div className={OnlineOffCss.subHead}>Exclusive features of our sharing platform are:</div>

                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200">
                        <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                        <div className=" pe-1">Teachers can assign homework in different formats such as image, PDF, Doc, Audio, Video, YouTube or other URL.</div>
                    </div>

                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400">
                        <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                        <div className=" pe-1">Homework submitted by students can be viewed.</div>
                    </div>

                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600">
                        <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                        <div className=" pe-1">Teachers can check homework.</div>
                    </div>
                    <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800">
                        <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                        <div className=" pe-1">Parents can view checked homework.</div>
                    </div>

                </div>
                <div className={OnlineOffCss.homeWorkBgImg}  data-aos="zoom-in" data-aos-duration="1000"></div>
            </div>
        </div>

        <div className="container my-5" data-aos="zoom-out-up" data-aos-duration="1500">
                <div className={OnlineOffCss.subContainer2}>
                    <div className={OnlineOffCss.ptmBgImg}  data-aos="zoom-in" data-aos-duration="1000"></div>
                    <div className={OnlineOffCss.Content2}>
                        <div className="fs-4 fw-700">PTM Progressive Profile</div>
                        <p>(Parents Teachers Meeting Progressive Profile) offers a unique holistic performance package for Teachers, Parents and Students of the ultra-modern age in accordance with NEP 2020.</p>

                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="200">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Systematic one-to-one interaction between parents and teachers.</div>
                        </div>

                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="400">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Proper report analysis due to inclusion of technology.</div>
                        </div>

                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="600">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Discussion about cognitive and kinesthetic development of a learner.</div>
                        </div>

                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="800">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Result and growth-oriented approach through ample activities.</div>
                        </div>

                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1000">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Provision of timely guidance and solutions for accelerated development.</div>
                        </div>
                        <div className={OnlineOffCss.indent} data-aos="zoom-out-up" data-aos-duration="1200">
                            <div className=""><ChevronRightIcon className={OnlineOffCss.iconList}/></div>
                            <div className=" pe-1">Personalised attention and invaluable insights.</div>
                        </div>

                    </div>
                    
                </div>
        </div>

      

    </saction>
   
      
  )
}

export default OnlineOffring
