import { Col, Container, Row } from 'react-bootstrap';
import OfferCss from './OnOffline.module.css';
import {useEffect} from 'react';
import Book1 from '../img/OnOfflineImage/Book1.webp';
import Book2 from '../img/OnOfflineImage/Book2.webp';
import Book3 from '../img/OnOfflineImage/Book3.webp';
import Book4 from '../img/OnOfflineImage/Book4.webp';
import Book5 from '../img/OnOfflineImage/Book5.webp';
import Book6 from '../img/OnOfflineImage/Book6.webp';

function OfflineOffer() {
  useEffect(()=>{
    window.$("#offlineSlide").owlCarousel({
    items:3,
    itemsDesktop:[1000,3],
    itemsDesktopSmall:[979,2],
    itemsTablet:[768,1],
    pagination:false,
    navigation:true,
    navigationText:["",""],
    autoPlay:true,
    }); 
  
  }, 
  
  []);
  
  return (
    <div className={OfferCss.offerBack}>
        {/* <div className={OfferCss.OfflineOffering}>Our Offline Offerings</div> */}
        <div className={`${OfferCss.onlineOffrng_header}`}>
                <h2>Our Offline Offerings</h2>
            </div>
       
      <Container>
        <div className={OfferCss.subHead}>
          For Students
        </div>
        <Row id="offlineSlide" className={OfferCss.owl_carousel}> 
          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book1} alt="" />
            </div>
          </Col>

          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book2} alt="" />
            </div>
          </Col>

          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book3} alt="" />
            </div>
          </Col>

          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book4} alt="" />
            </div>
          </Col>

          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book5} alt="" />
            </div>
          </Col>

          <Col className="col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <img src={Book6} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default OfflineOffer;
