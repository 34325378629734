import img0 from '../img/blog/img0.webp';
import img01 from '../img/blog/img01.webp';
import img02 from '../img/blog/img2.webp';
import img03 from '../img/blog/img03.webp';
import img04 from '../img/blog/img04.webp';
import img05 from '../img/blog/img05.webp';
import img06 from '../img/blog/img06.webp';
import flowChart from '../img/blog/Blog_8flowChapt.webp'
import img07 from '../img/blog/img07.webp';
import img08 from '../img/blog/img08.webp';
import img09 from '../img/blog/img09.webp';
import img10 from '../img/blog/img10.webp';
import img11 from '../img/blog/img11.webp';
import img12 from '../img/blog/img12.webp';
import img13 from '../img/blog/img13.webp';
import img14 from '../img/blog/img14.webp';
import img15 from '../img/blog/img15.webp';
import img16 from '../img/blog/img16.webp';
import img17 from '../img/blog/img17.webp';
import img18 from '../img/blog/img18.webp';
import img19 from '../img/blog/img19.webp';
import blogTable from '../img/blog/blog_table.webp';
// import noImg from '../img/blog/noImg.jpg'

import BlogCss from './Blog.module.css'



export default [
  {
    'id': 1,
    'banner': img0,
    'heading': 'Let’s enter the world of Swa-Adhyayan via Digital Learning',
    'url': 'lets-enter-the-world-of-swa-adhyayan-via-digital-learning',
    'date': 'JANUARY 7, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `We are striving to deliver quality education driven by Digital learning to our learners.`,
    'content': <div className={BlogCss.msgText}>Swa-Adhyayan is an Adaptive Digital Learning Platform designed to cater the needs of the students by unleashing the Learning   Aptitude through a potential genius. The LMS is not merely an Online Learning Platform but also an intrinsic part augmented by Swa-Adhyayan Textbooks and workbooks for K-8 and supported by complimentary NEP and ECCE Kits for teachers. The various aspects of the spectacular Learning Management System are extremely synchronised. It helps the child to cross the zone of proximal development and leap to the level of understanding in-depth. Swa-Adhyayan includes both the learning and assessment processes. <br />
      Swa-Adhyayan is the unique Learning, Assessment and Sharing Platform based on Multiple Intelligences. It aids to streamline the teaching process and improves student/classroom and ultimately the school outcomes. The Online Learning Platform offers interesting and stimulating module to identify the preferred learning styles of children and build their progressive proficiency. Teaching Aids offered by the LMS is a visual delight for learners at all levels to enable them in internalising the concepts.

      <div className={BlogCss.textHead}>5 best Solutions to develop Multiple Intelligences</div>
      <ol>
        <li><b>SEPT–</b> Gateway to Swa-Adhyayan’s Learning System<br />
          This unique pre learning diagnostic Assessment enables the schools, teachers, parents and students to know the Academic Proficiency Profile, Learning Styles, Multiple Intelligences, Brain Dominance, the Personality traits of the learners. It displays the personality traits of the learners thereby fostering the teaching-learning process.
        </li>
        <li><b>Swa-learning –</b> Swa-Adhyayan means Self-learning!<br />
          Let’s surpass PDFs and E-books and access enriched contents comprising of 1500+ Practice Questions for each subject for the respective classes. A vast combination of customisable activities, games, multimedia resources, educational videos, practice exercises and assessments is all in the bucket.
        </li>
        <li><b>Swa-assessment – </b>A positive reinforcement for learners for better performance<br />
          It has inclusive and progression based assessments on Multiple Intelligences fuelled by school curriculum to develop the abilities to analyse the learning status. The interactive exercises are divided into Engage, Explore and Extend (levels of difficulty).
        </li>
        <li><b>Online Reports –  </b>Assessment Reports with Graphical Representation on all 4 dashboards<br />
          The Reports on the Digital Learning Platform are generated with graphical representation to track the progress of a child on numerous parameters. This helps the teachers to learn students’ problems and give the suggestive measures to bring the required improvement in learning.
        </li>
        <li><b>Swa-sharing – </b>Integrated Platform for Sharing<br />
          It connects the users to access anytime anywhere by providing digital assets and enables them to share useful resources like videos, audios, images, etc., and learning outcomes. Teachers can share and modify their contents through this by enabling open forum for interactions. It serves as an engaging and stimulating feedback system.
        </li>

      </ol>


      <div className={BlogCss.textHead}>What does it offer on disparate dashboards for?</div>
      <ul>
        <li><b>TEACHERS:</b> Assign Learning, Assign Assessment, Test Generation, Student Attendance and Sharing
        </li>
        <li><b>STUDENTS:</b> SEPT (Swa-Adhyayan Entrant's Profiling Test), Learning, Assessment and Progress Report
        </li>
        <li><b>PARENTS:</b> Children's Reports, Notes/Notifications, Practice Lessons , Feedback and Progress Tracking
        </li>
        <li><b>SCHOOLS:</b> Managing Teachers, Students' Reports, Attendance and Timetable
        </li>
      </ul>

      <div className="">Learning, Activities, Practice Exercises, Gamification and Assessments on this Digital Platform are mapped as per the latest curricula as well as keeping NEP 2020 Guidelines into consideration. As the major shift in Education system as per the National Education Policy 2020, this platform provides a promising step for 21st Century learners. Schools can add the subjects according to their curricula. Listed below, are the subjects this platform covered as per the Grades.</div>
      <ul>
        <li><b>Pre-Primary-</b> English / Hindi /Mathematics/ EVS / Art and Craft</li>
        <li><b>Grades 1 to 5-</b> English / Hindi /Mathematics/ Science / Social Science / EVS / Art and Craft</li>
        <li><b>Grades 6 to 8-</b> English / Hindi /Mathematics/ Science / Social Science</li>
      </ul>

      <div className={BlogCss.textHead}>Our Deliverables:</div>

      <ul>
        <li>Orientation Sessions for Teachers/Students/Parents</li>
        <li>Training Sessions for School Administration / In–charge</li>
        <li>CBSE SAFAL Sample Assessment </li>
        <li>Teacher’s Handbooks with Competency based Activities</li>
        <li>Online Student’s Handbooks/Activity books with Competency based Activities</li>
      </ul>
      <div>Get in touch with us for Swa-Adhyayan Demo Session at: customersupport@swaadhyayan.com or connect with us on 011-43585858</div>
    </div>
  },
  {
    'id': 2,
    'banner': img01,
    'heading': 'Pre-learning Personality Test for the Students to know their Learning Styles',
    'url': 'pre-learning-personality-test-for-the-students-to-know-their-learning-styles',
    'date': 'JANUARY 4, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan is a distinctive Digital Platform to LEARN, ASSESS and SHARE. The adaptive learning platform for Students, Teachers, Parents and Schools is supported by NEP aligned Textbooks, Workbooks, Teacher's Resource Manuals(TRMs), Handbooks, Activity Books and NEP and ECCE Teacher’s Resource Kits for classes K-8. The Learning Management System (LMS) offers nonpareil features to remove all the doubts related to Digital Learning Concept.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>Swa-Adhyayan Entrant’s Profiling Test (SEPT)</div>
        <div>Swa-Adhyayan Entrant’s Profiling Test is the pre-learning diagnostic assessment tool to analyse a child’s preferred learning style and proficiency based on ‘Multiple Intelligences’. SEPT extends the remediation for students and empowers the educators to streamline their teaching process to make the learning process more effective and efficient. <br />
          'The Profiling Test' includes 5 Tests to test the sensory abilities, dominant intelligence areas and the personality traits of the students. They are: </div>
        <ol type='A'>
          <li style={{ marginTop: '10px' }}><b>Academic Proficiency Profile: Level C to 8</b><br />
            Beginner, Average, Advanced or Proficient
          </li>
          <li style={{ marginTop: '10px' }}><b>Learning Style: Level C to 8</b> <br />
            Auditory, Visual or Kinesthetic
          </li>
          <li style={{ marginTop: '10px' }}><b>Multiple Intelligences: Level 3 to 8</b><br />
            Interpersonal, Intrapersonal, Logical, Visual, Musical, Linguistic, Naturalistic or Kinesthetic
          </li>
          <li style={{ marginTop: '10px' }}><b>Brain Dominance: Level 3 to 8</b><br />
            Left Brain or Right Brain
          </li>
          <li style={{ marginTop: '10px' }}><b>Knowing Me: Level 3 to 8</b><br/>
            Experimental, Inquisitive and Imaginative, Orderly, Disciplined, Friendly, Energetic, Extrovert, Compassionate, Kind, Sympathetic, Even-tempered, Composed
          </li>
        </ol>


        <div className={BlogCss.textHead}>Why is SEPT essential?</div>
        <div className="">SEPT is the first step for students to begin their online learning process. As they dive into the world of digital learning via Swa-Adhyayan, they meet themselves at first. This online test gives a brief information of an entrant’s strengths, weaknesses, skills, knowledge and conceptual understanding and cognitive abilities. The assessment is done to know the level of 8 Multiple Intelligences in them. This test helps the learners to identify their own learning styles, proficiency in a particular field, scholastic and co-scholastic abilities. SEPT being a pre-instruction test provides information on possible learning outcomes on adopting a particular method for each child. It provides suggestions on how to deal with the uniqueness in each child. It involves both parents and teachers as well as the students and properly defines role and responsibility of each of them in the progress of a child. </div>

        <div className={BlogCss.textHead}>SEPT: Knowing Me</div>
        <div className="">After taking the test, the system provides remedial adaptive lessons for the student followed by enhancement lessons and practice lessons before he/she moves onto next topic. ‘Knowing Me’ will help to identify the characteristics of a child’s nature to be rated on the following parameters:</div>

        <ul>
          <li>Experimental, inquisitive and imaginative</li>
          <li>Orderly and disciplined</li>
          <li>Friendly, energetic and extrovert, compassionate, kind and sympathetic</li>
          <li>Even-tempered and composed</li>
        </ul>
        <div className="">SEPT provides with the academic readiness of the child, supported by remedies and suggestions accordingly.</div>
        <div className={BlogCss.textHead}>REPORTS</div>
        <div className="">Swa-Adhyayan Entrant’s Profiling Test displays the types of personality of the learners by fostering teaching-learning process to empower the Students | Teachers | School | Parents to understand and evaluate the academic progress via REPORTS. SEPT Analysis provides us a benchmark for academic readiness of the child in the form of Reports with dynamic graphical representation as every single child is indexed separately.
          The final report of SEPT Analysis can provide succour for annual curriculum plan of the child.<br />
          For students, it serves as the medium to understand themselves, their cognizance and their academic status providing insights on the way they should study and how they can help themselves. For teachers, this analysis can help in laying down the framework for curriculum. For parents, they can understand how much of their involvement is essential for the child.
        </div>
        <div>Get in touch with us for Swa-Adhyayan Demo Session at: customersupport@swaadhyayan.com or connect with us on 011-43585858</div>
      </div>

  },
  {
    'id': 3,
    'banner': img02,
    'heading': 'How Blended Education via Digital LMS is helpful for the learners',
    'url': 'how-blended-education-via-digital-lms-is-helpful-for-the-learners',
    'date': 'JANUARY 4, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `The thought of studying online via Digital LMS could seem to exasperating at times. It could increase the difficulty to understand: Can an educational e-learning site be developed in a manner to cater to all the Digital learning requirements of a student? Is it possible to get online learning resources on any of the students' e-learning platform?`,
    'content':
      <div className={BlogCss.msgText}>
        In the middle of so many question marks, comes a full stop to the queries that’s Swa-Adhyayan being the best e-learning platform for students.
        <div className={BlogCss.textHead}>What is Blended Education?</div>
        <div className="">Blended Education refers to the style of education where in the students learn through an online learning platform via electronic media as well as the traditional  learning methods, i.e., face to face mode of teaching. A study suggested that even when the parents could have a neutral or positive perspective towards online learning still their understanding towards it could still be limited. Calling Swa- Adhyayan, to be an example of Blended Learning Approach won’t be unfair. <br />
          Here’s a guide to understand Blended Education via Digital LMS is helpful for the learners. Let’s get started.
        </div>

        <div className={BlogCss.textHead}>Swa-Adhyayan: The Future of Education</div>
        <div className="">Swa-Adhyayan, the Learning Management System (LMS) for the students of Kindergarten to Grade 8 provides learning, assessment and sharing Platform supported by NEP aligned Textbooks, Workbooks, Handbooks along with the educator-led learning. It has been remarkably designed in accordance to the National Education Policy 2020. All the digital learning resources such as Activities, Practice Exercises, Gamification and Assessments are mapped as per the latest Curricula for Kindergarten to Grade 8. Swa Adhyayan ensures the conceptual clarity to its learners through visualisation. Various Learning programs and digital assets provide quality education to the learners. Swa-Adhyayan, the best educational learning platform doesn’t deserve even to be compared with random educational videos for learners as it’s a full-fledged formal education program to impart knowledge by providing access over the unmatched subject matter, concepts and pace of learning. Learning model of educational e-learning site proves that a child could now learn whenever and wherever he/she wants.</div>

        <div className={BlogCss.textHead}>How Swa-Adhyayan is different from other E-learning Platforms?</div>
        <div className="">Learning can happen in different ways and children need not to restrict themselves to a specific approach. With the intent, an individual is taught with respect to his/her learning pace based on the Multiple Intelligences. Obviously it’s not an alternative education system but a system to manage the learning. To be precise, it’s an LMS—Learning Management System that offers flexibility and control over learning.</div>
        <div> “Trust Us, when we Promise to give Quality Contents.”</div>
        <div className="">The core of Swa-Adhyayan LMS, enhances the better outcomes of the learners in terms of education.</div>
        <ol>
          <li><span style={{ fontWeight: 'bold' }}> Credibility of the Online Reference Materials </span><br />
            Parents are cautious while looking for online reference materials or digital resources; differentiate between a trusted and non-trusted resource. Swa-Adhyayan directs you to the best one. The credibility of our reference materials is and will remain nonpareil and access to them would fetch you unmatched results.
          </li>

          <li><span style={{ fontWeight: 'bold' }}> Enforce structure to make the best learning </span><br />
            Swa Adhyayan blurs the line of gap between schools and children. Help your child establish a schedule for learning online. We have offering for both online and offline reading. , it offers the richest and the most interactive learning experience to the students.
          </li>

          <li><span style={{ fontWeight: 'bold' }}> Probe to Child’s Progress </span><br />
            Report builds a connection with the schools to evaluate children’s progress by understanding how the children are doing in different subjects, their strengths and areas of improvement. Reviewing progress helps to bring required improvements accordingly. Students/Parents and Teachers can track how the child is doing in the class.
          </li>
        </ol>
        <div className="">Swa-Adhyayan enables to make informed choices and foster a ‘Never Stop Learning’ attitude in learners. It helps teachers to understand how to support the children better to maximise their learnings and highlights parents’ role in the educational segment of a child’s life. </div>
        <div className="">Get in touch with us at: info@swaadhyayan.com or Connect with us on 011-43585858</div>
      </div>

  },

  {
    'id': 4,
    'banner': img03,
    'heading': 'The Benefits of using Online Learning Apps to enhance your Educational Prospects ',
    'url': 'the-benefits-of-using-online-learning-apps-to-enhance-your-educational-prospects',
    'date': 'FEBRUARY 7, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'Digital learning is becoming increasingly popular as it offers a convenient way of learning. Swa-Adhyayan is an adaptive e-learning platform that enables the students to learn from anywhere and at any time. The learners can even track their progress and gain insights into their performances. This helps them to stay motivated and focused towards their learning goals. Online learning platform provides an effective way to access digital resources to gain knowledge while being a part of the entire process. It also equips the learners with the latest and updated educational advancements. ',
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>Introduction</div>
        <div className="">Swa-Adhyayan, has revolutionised the way of learning. With its intuitive user interface, students can easily find the topics they are looking for and explore different learning paths. The platform also provides personalised learning experiences with tailored contents for each student based on his/her interest and ability. With its interactive features, it allows students to engage with their peers, enjoy interactive assessments and learn through gamification. Hence, it enables them to gain better understanding of the topics. Swa-Adhyayan is an excellent example of how technology can be used to enhance the learning experience for all kinds of learners. Moreover, the online app of a digital learning platform helps in educational advancement by providing assessments and feedback in real-time that leads to better understanding and faster learning outcomes. <br />
          The online app helps in the educational advancement of students by providing them with various learning and assessment tools to make learning more effective. Moreover, it provides a platform for digital collaboration among students and teachers/schools through Swa-Adhyayan, which enables them to assess and share ideas on various topics related to their coursework.<br />
          The applications to support the Integrated Learning are as follows:
        </div>
        <ol>
          <li style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Swaadhyayan LMS</span><br />
            The online learning App helps students/parents/teachers to access extensive range of digital resources like audios, videos, images, documents, YouTube links, etc., to learn more effectively. The useful resources can be accessed anytime, anywhere through this application. Swa-Adhyayan LMS enables teachers to manage the assessment process and track students' progress. Furthermore, it enables schools to provide personalised learning experiences to their students. Activities and assessments for Kindergarten to Class 8 are mapped to the latest curricula. The Multiple Reports and Progress Cards generated on the basis of assessments depict various parameters. The Phenomenal combination of engaging video lessons and personalised learning, special worksheets for learners are also there based on their performances.
          </li>

          <li style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>SWAR App</span><br />
            Swa-Adhyayan introduces Augmented Reality App with its textbooks. This app has an enhanced interactive contents beyond the books where teachers/students can get all chapter animations on the screen with one touch on their smart phones and tablets.
          </li>
        </ol>
        <div className="">Links to download the Apps:</div>
        <ul>
          <li style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Swa-Adhyayan App</span><br />
            <a href='https://play.google.com/store/apps/details?id=com.swaadhyayanlmsandhl=en' target='_blank'>https://play.google.com/store/apps/details?id=com.swaadhyayanlmsandhl=en</a>
          </li>
          <li style={{ marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>SWAR App</span><br />
            <a href='https://play.google.com/store/apps/details?id=foreverappar.com.foreverappar' target='_blank'>https://play.google.com/store/apps/details?id=foreverappar.com.foreverappar</a>
          </li>
        </ul>
        <div> Overall, an online app of a digital learning platform is an invaluable tool. It provides numerous advantages which help in promoting educational advancement and making learning more accessible for all kinds of learners. Visit us at <a  href='https://swaadhyayan.com/ ' target='_blank'>https://swaadhyayan.com/ </a></div>

      </div>
  },
  
  {
    'id': 5,
    'banner': img04,
    'heading': 'How the Online Offerings of Swa-Adhyayan make it the best of all E-learning Platforms',
    'url': 'how-the-online-offerings-of-swa-adhyayan-make-it-the-best-of-all-e-learning-platforms',
    'date': 'FEBRUARY 10, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'Swa-Adhyayan, unlike other e-learning platforms available in the market proffers both online and offline digital learning resources to the students to learn from. The interactive contents in the books, workbooks and in the digital assessment sheets help the students to be well-versed with the concepts in the most innovative manner. Unique dashboard has been dedicatedly designed to meet the user’s requirement, be it a student, teacher, parent or the school administration, is a big plus to Swa-Adhyayan. The digital learning platform engages learners from Kindergarten to Grade 8 entailing a series of activities based on Multiple Intelligences to promote Digital Literacy among its users.',
    'content':
      <div className={BlogCss.msgText}>
        <div className="">Digital Literacy refers to the ability of an individual to find, evaluate and communicate the information through typing and other media on various digital platforms. In all the terms, it is mandatory to be well-versed with the complexities while handling/learning through any Digital Learning Platform. 
        </div>
        <div className={BlogCss.textHead}>How Swa-Adhyayan stands out the best of all E-learning Platforms</div>
        <ul>
          <li>It is the most unique and adaptive LMS, for Learning, Assessment and Sharing for students.</li>
          <li>It identifies learning style of students.</li>
          <li>It builds proficiency of students through Multiple Intelligences based Assessments. </li>
          <li>It allows to access the Digital Resources anywhere and anytime.</li>
          <li>Swa-Adhyayan is supported by Textbooks, Workbooks, ECCE aligned Teachers’ Kits for Grades K-8.</li>
          <li>All the Activities, Exercises, Gamification, Assessments are mapped as per the latest curricula. </li>
          <li>It provides Online Students’ Handbooks and Activity Books with Competency based Activities.</li>
        </ul>

        <div className={BlogCss.textHead}>Swa-Adhyayan’s Prominent Role:</div>
        <ul>
          <li><b>Students:</b> Swa-Adhyayan, the Digital Learning Platform helps the students to know the best way to study and learn as per their preferred learning styles.</li>
          <li><b>Teachers:</b> It identifies and defines the responsibility of teachers/schools for continuous evaluation of learners. The innovative teachers can create and customise their own interactive assessments on their favorite online platform.</li>
          <li><b>Parents:</b> It helps the parents to keep a track of their wards’ progress at school.  </li>
          <li><b>School Administration:</b> Swa-Adhyayan LMS, helps the administration to manage attendance and reports of both the teachers and students.</li>
        </ul>

       <div className={BlogCss.textHead}>Offline Offerings</div>
        <ol className={BlogCss.olBold}>
          <li>Textbooks and Workbooks (A-8)</li>
          <li>Educational Games (A-5)</li>
          <li>Educational Manipulatives (1-8)</li>
          <li>Subject Charts (A-8)</li>
          <li>Subject Table Calendars (A-C)</li>
        </ol>

       <div className={BlogCss.textHead}>Online Offerings</div>
        <ol>
          <li>1500+ Practice Questions per class and per subject<br/>
            English (A to 8), Hindi (A to 8), Mathematics (A to 8), EVS (A to 5), Art and Craft (A to C), Science (1 to 8) and Social Science (1 to 8)
          </li>
          <li>Assessment Reports</li>
          <li>Assessments and Homework</li>
          <li>CBSE SAFAL Sample Assessment at <b>lms.swaadhyayan.net</b></li>
        </ol>

        <div> Swa-Adhyayan, the online Learning Platform knows that deliverance of quality contents to the educators and learners is of paramount importance. Considering which, our expert panel creates the textbooks and digital resources like audios, documents, videos with the most authentic contents. We believe that Education merely can’t bring Success, Working Together is what results in SUCCESS.</div>
        <div className="text-center fw-bold">“Learning via Swa-Adhyayan is forever.”</div>
      </div>
  },

  {
    'id': 6,
    'banner': img05,
    'heading': 'Key benefits of Swa-Adhyayan: Learning Management System for Students',
    'url': 'key-benefits-of-swa-adhyayan-learning-management-system-for-students',
    'date': 'FEBRUARY 20, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'E-learning or virtual learning platforms have gained much popularity among learners as they make the path of learning more accessible and feasible for its users. Technology has given rise to many advancements. The world of education has been transformed in recent years, bigger credit goes to the emergence of e-learning platforms. The digital learning resources have provided new opportunities for the students to learn and grow. The most essential tool to succeed in their academic pursuits is the e-learning resources provided by Swa-Adhyayan.',
    'content':
      <div className={BlogCss.msgText}>
        <div className="">Let’s explore the key benefits of Swa-Adhyayan: Learning Management System for students. 
        </div>
        <ol>
          <li><b>Accessibility:</b> Accessibility is one of the most significant advantages of e-learning platforms. The students have access to the resources of the best digital learning platform from anywhere, at any time. Unlike other customary e-learning sites, Swa-Adhyayan has set inimitable standards in the field of online education. Swa-Adhyayan proffers flexibility to the users to learn at their own pace and their own schedules.</li>
          <li><b>Cost-Effectiveness: </b>E-learning platforms are cost-effective. Traditional classroom education can be expensive, especially when it comes to textbooks and other materials. With the best educational learning platform, students can access a wide range of resources at a fraction of cost.</li>
          <li><b>Personalised Learning: </b> E-learning platforms also provide a more personalised learning experience as the student’s Multiple Intelligences based proficiency is built. With the LMS, teachers create customised assessment sheets which cater to the unique learning needs of each student. This is in contrast to traditional classroom settings, where everyone is expected to learn at the same pace, regardless of their individual learning abilities. </li>
          <li><b>Educational Resources:</b> Online learning platforms provide a wider range of educational resources. Students can have the access to audios, course materials, videos, and other digital resources aligned with the textbooks at their fingertips. Students' aren’t limited to the knowledge of the books. Gamifications, Interactive Assessments and online Exercises summarise the broader horizons of it.</li>
        </ol>


        <div> In culmination, E-learning platforms are becoming popular by making learning more engaging. Students can participate in online discussions, complete interactive assessments, and track their progress throughout the session. They can bring the classroom to Home.  Swa-Adhyayan develops communicative skills, self-learning, critical thinking abilities and much more.<br/>
        Interactive learning experience with the Online learning platform has opened doors of invariably greater opportunities for students. Students have access to high-quality education via Swa-Adhyayan, which has been ranked as the best educational learning platform. Swa-Adhyayan deliverables are flexible, affordable, and engaging, hence, have no brackets to limit the learning. We have unique dashboards for students, teachers, schools and parents too. </div>
        <div className="">Learn more about us: <a href='https://swaadhyayan.com/ ' target='_blank'>https://swaadhyayan.com/ </a></div>
      </div>
  },

  {
    'id': 7,
    'banner': img06,
    'heading': `Accessing Swa Adhyayan is at Your Fingertips `,
    'url': 'accessing-swa-adhyayan-is-at-your-fingertips',
    'date': 'FEBRUARY 28, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'Learning software is becoming more and more popular as a way to facilitate learning in classrooms and online. Steps to accessing a learning software can be a great way to streamline educational resources and coursebooks. The perfect integration for learning brings improved outcomes. selecting the right software, students, teachers and school administration, all are benefitted with access to digital resources. Accessing a learning software can be a daunting task if in case the User Interface is challenging in any way. Learning Management System provides the resources to maximise the learning experience of the students.',
    'content':
      <div className={BlogCss.msgText}>

        <div className="">This blog looks at the steps required to access a Learning Management System (LMS) for students. From setting up accounts, understanding various features of the programme, to accessing the digital assets, we pave a way to Learners’ Academic Growth. A successful setup of the LMS will allow users to get the most out of their educational experiences and provide them an easy-to-use platform to go through the NCERT textbooks based on interactive contents, Assessment Sheets, Gamification, Progress Report and much more. Many School administrations have opted for Swa-Adhyayan—the Digital Learning Platform. We have Swa-Adhyayan users in PAN India. Let’s see how we can access this adaptive e-learning platform for Students/Teachers/Parents and School Administration. 
        </div>
        <div className='mt-4'>There are some key steps that you must follow to access Swa-Adhyayan Platform:</div>
          <div className={BlogCss.stepStyle}><b>Step 1 : </b>Open the Web Link <a href='https://lms.swaadhyayan.net/accessCode' target='_blank'>https://lms.swaadhyayan.net/accessCode</a></div>
          <div className={BlogCss.stepStyle}><b>Step 2 : </b>Enter the Access Code (of any subject) given on inner title cover of the book.</div>
          <div className={BlogCss.stepStyle}><b>Step 3 : </b> Enter your School Code (to be obtained from School/Teacher).</div>
          <div className={BlogCss.stepStyle}><b>Step 4 : </b>Fill the Registration Form with the required details.</div>
          <div className={BlogCss.stepStyle}><b>Step 5 : </b>After the successful registration, Login Credentials and Access Link will be sent on registered Email ID. </div>
          <div className={BlogCss.stepStyle}><b>Step 6 : </b>Click on the Link and Login with your User Credentials to access the most comprehensive Digital Learning Platform.</div>
        
        <div> Once the account is set up, it’s the time to start exploring the programme. You should take some time to get familiar with all of its features so that you can make the most out of it when teaching or learning through the best LMS. Start accessing the courses and assignments through the learning programme. Additionally, we'll talk about how to use your programme after you gain access, including best practices for staying motivated and successful in your online studies. With these tips in mind, you should be able to make the most out of the LMS- learning Management System or online platform available today!</div>
        <div className="m-2"><b>For any query write us:</b> info@swaadhyayan.com</div>
      </div>
  },
  {
    'id': 8,
    'banner': img07,
    'heading': `Swa-Adhyayan: the best Digital Platform with Hybrid Mode of Learning`,
    'url': 'swa-adhyayan-the-best-digital-platform-with-hybrid-mode-of-learning',
    'date': 'MARCH 4, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'In the era of technology, transformation of education is the need of the hour. Schools have started adopting e-learning platform with Hybrid mode of Learning which combines traditional in-person teaching with online learning. This mode of learning has become a necessity to add the benefits for both students and teachers. The Adaptive Digital Learning Platform (ADLP) offers activity-based learning to give hands-on learning experience to the students.',
    'content':
      <div className={BlogCss.msgText}>
        <div className="">Swa-Adhyayan offers the attributes of e-learning platform along with textbooks aligned teaching methodology which is an essential part of the hybrid mode of learning, as the students can access the textbooks online and practise them as per their own convenience. This LMS offers additional resources such as videos, animations, Virtual Tour and simulations which help students to understand complex concepts with much ease. By strengthening the subjects’ foundation, the students can retain the information for a longer time. 
        </div>
        <div className={BlogCss.textHead}>How we offer Hybrid Learning</div>
        <div className="">
          <img src={flowChart} alt=""/>
        </div>
        <div className={BlogCss.textHead}>Swa-Adhyayan Digital Resources </div>
        <div className={BlogCss.msgText}>Digital Resources of Swa-Adhyayan for Kindergarten to Grade 8 students include educational videos, gamification, and assessments which are the key aspects. These resources are easily accessible and can be used by students anytime, anywhere. Assessment helps in creating Question Paper and checking the knowledge of the students. The questions include MCQs, Lab Activities, Project Work and Interactive Activities. These assessments help students understand their strengths and weaknesses in the subjects and help them improve their performances. This approach helps students learn at their own pace and also helps teachers to personalise learning for each student.</div>
        <div className={BlogCss.textHead}>Benefits of Swa-Adhyayan</div>
        <ul>
          <li>Activity-based learning is an effective way to engage students in the learning process.</li>
          <li>This approach helps students understand the practical application of the concepts.</li>
          <li>It offers experiential learning. </li>
          <li>It makes learning fun and interactive.</li>
          <li>This flexibility enables students to learn at their own pace.</li>
          <li>It allows teachers to provide personalised learning for each student.</li>
          <li>Adaptive Assessments cater to the individual needs of each student.</li>
        </ul>
        <div className={BlogCss.msgText}>In conclusion, the Hybrid Mode of Learning is a boon for students and teachers alike. It has become a necessity to achieve holistic excellence in the current times. With the help of Swa-Adhyayan, students achieve Academic Excellence in flexible and stress-free learning environment. It also offers a customised learning experience to the learners. Swa-Adhyayan has the potential to revolutionise the entire education system to make learning more accessible and effective for students.</div>
        <div className="m-2"><b>Visit : </b><a href='https://swaadhyayan.com/lms/'>https://swaadhyayan.com/lms/</a>to Know More.</div>
      </div>
  },
  {
    'id': 9,
    'banner': img08,
    'heading': `Online Learning with Swa-Adhyayan is Advantageous in all situations`,
    'url': 'online-learning-with-swa-adhyayan-is-advantageous-in-all-situations',
    'date': 'MARCH 29, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Online/Digital Learning Platforms have surfaced as a mandatory learning resource for all ages. With immense learning scope, many online portals have been developed. Among options, we don't get confused, so it is very important to choose the best digital learning platform out of the existing ones.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className="">Here comes, Swa-Adhyayan, LMS for Kindergarten to Grade 8 students. Swa-Adhyayan is an Adaptive Digital Learning Platform (ADLP) for the students to get a revolutionary way of learning, assessing, and sharing. Swa-Adhyayan Entrant's Profiling Test (SEPT), Swa-learning, Swa-assessment, Swa-sharing, Reports, Homework, etc., makes it more conspicuous. The most prominent LMS is supported by NEP 2020 aligned textbooks, workbooks and other resource materials. 
        <br/> 
        Everything comes with some Pros and Cons. When we talk about the best e-learning platforms, the positives are worth more than the negatives of the platforms providing digital education. Here, we put a light on Swa-Adhyayan deliverables. 
        </div>
        <div className={BlogCss.textHead}>Advantages</div>
        <ul>
          <li>Swa-Adhyayan—the digital learning platform provides modern teaching-learning methods.</li>
          <li>It proffers online learning tools such as Images, PDFs, Videos, lessons, and homework digitally.</li>
          <li>It removes the geographical boundaries as the students can learn anywhere and anytime.</li>
          <li>There are no additional costs to have digital education via LMS.</li>
          <li>Online Learning is affordable as compared to the conventional method of learning.</li>
          <li>Students learn beyond the textbooks.</li>
          <li>It builds Subject and Concept Proficiency.</li>
          <li>It makes Learning more interesting and interactive. </li>
        </ul>
        <div className={BlogCss.textHead}>Drawbacks</div>
        <ul>
          <li>Loss of concentration due to longer screen is one of the drawbacks of online learning.</li>
          <li>Internet accessibility sometimes divert the mind.</li>
          <li>For teachers, it is a bit challenging to make each child attempt the assigned work as enforcement is no option in any case. </li>
          <li>Internet connectivity issue may hamper the process of Online Learning.</li>
        </ul>

        <div className={BlogCss.textHead}>Conclusion</div>
        <div className={BlogCss.msgText}>As discussed above, the advantages win over the disadvantages of digital education with the best LMS, i.e., Swa-Adhyayan. The learning platform with hybrid mode of learning is a constructive online portal to promote e-learning at its best. The drawbacks of the learning portal are easily escapable without hampering its advantages. Swa- Adhyayan provides a unique way of learning to make the learning an interactive process for students, teachers, parents, and schools.</div>
        <div className="m-2"><b>Know more at: </b><a target='blank' href='https://swaadhyayan.com/'>https://swaadhyayan.com/ </a></div>
      </div>
  },

  {
    'id': 10,
    'banner': img09,
    'heading': `Top Benefits of Reaching Paramount in Digital Learning via Swa-Adhyayan`,
    'url': 'top-benefits-of-reaching-paramount-in-digital-learning-via-swa-adhyayan',
    'date': 'APRIL 11, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'Digital World asks for new adaptations. A student transforming into an active learner is a primary aspect to be the right fit to Digital Learning. However, searching for the best online learning platform for students has always been a tedious task not only for the parents but for the educators too. Swa-Adhyayan is an Adaptive Digital Learning Platform for Kindergarten to Grade 8 students that offers Hybrid learning to them. The fusion of Conventional and Digital Method of Teaching has indeed made the education more interactive. Among the available options; opting for the best online learning platform, becomes challenging. Swa-Adhyayan, the premier Learning Management System, with its exemplary features stands top among other e-learning platforms for schools/students/teachers.',
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>How Swa- Adhyayan is the Best Online Learning Platform</div>
        <ul>
          <li>Understanding the Concepts via Audios/Videos is easier </li>
          <li>Limitless Educational Resources</li>
          <li>Students can view the Documents, 2D and 3D videos. </li>
          <li>E-learning platform for schools which allow access to Online Learning resources. </li>
          <li>It makes students prepared with the concepts covered so far.</li>
        </ul>
        <div className={BlogCss.textHead}>Digital Learning vs Hybrid Learning</div>
        <div className={BlogCss.msgText}>Digital learning involves learning through technology or instructional practice to get effective outcomes. It encompasses wide spectrum of practices, such as blended and virtual learning. Digital learning facilitated by technology gives some elements of control over time, place, and pace to the students. It has become popular among the learners of modern era, as they are intended to do self-learning beyond textbooks. Hybrid mode of learning gives freedom to the learners to engage themselves with the course materials and learn on their own pace whether it is online or offline (in-person) mode which allows them to retain more information at a higher rate.</div>
        <div className={BlogCss.msgText}>The purpose of online learning is to make the learners skilled. Swa-Adhyayan, the best digital learning platform for students/teachers/parents/schools enables its users to find, access and share the digital learning resources. The list of immense benefits of e-learning platform for school includes personalised learning with breadth of information via engaging lessons. It proffers the learning flexibility by providing recorded contents with facility of accessing digital resources 24 hours. Swa-Adhyayan involves educators and parents both in the process of education and enables them to track students’ progress. Sharing of Information is also permissible on the most premier Learning Management System.</div>
             
        <div className={BlogCss.msgText}>From Chalkboards and Parchment paper to Smartboards and Laptops, the learning scope has completely transformed. These changes will definitely embark today’s learners into an advance future with unlimited possibilities. Click <a target='blank' href='https://swaadhyayan.com/  '>https://swaadhyayan.com/ </a>  to learn more about the best online learning platform for Kindergarten to Grade 8 students.</div>
      </div>
  },

  {
    'id': 11,
    'banner': img10,
    'heading': `Swa-Adhyayan Students Reports—Apt for Continuous Academic Analysis`,
    'url': 'swa-adhyayan-students-reports-apt-for-continuous-academic-analysis',
    'date': 'MAY 8, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': 'Swa-Adhyayan, the online e-learning platform is compatible with all types of devices provided with an internet. What keeps the platform at the top is the compartmentalisation of contents where different features are made available to students, teachers, parents and school authorities. The amount of access has been provided as per the hierarchy of responsibility of the stakeholders. For that purpose, each of them is provided with uniquely designed dashboard.',
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.msgText}>The most noteworthy feature of Swa-Adhyayan digital learning platform for schools is the ability to handle and provide reports after processing the data. Reports generated on Adaptive Digital Learning Platform includes Graphical Representation that makes it easy to understand. Moving away from the conventional method of presenting the marks of students in tabular forms, the reports represent the students' data via graphs, pie charts, etc. It also provides the facility of maintaining repository of Reports which students can navigate through the progress made during the entire session. Students’ Reports help them in achieving excellence on the academic front. They can track their own progress via chapterwise reports, subjectwise reports, etc., which can be put to use for comprehensive comparative analysis. Students also have the facility to view Half Yearly and Annual Reports with the opportunity of having a glance at the Dynamic Representation. The data gets updated timely and students can gauge their performances so that they can know and focus on the weaker sections. </div>
        <div className={BlogCss.msgText}>“Swa-Adhyayan encourages and supports learning like none other.”</div>
        <div className={BlogCss.msgText}>The Data present on the Teacher’s Dashboard is extremely helpful to conduct classwise analysis. The best part about receiving reports is that it allows comparative analysis. The above LMS Software provides the facility of manipulating data as per the role of the stakeholders on the platform and their contribution in the student’s educational journey. The school administration can also keep a track of the time spent by the teachers and students on a particular content. The teachers can also examine how far the contents were received by the students and also analyse their performances. Therefore, Swa-Adhyayan makes the job of Handling Data and Analysis on it much easier. </div>
             
        <div className={BlogCss.msgText}>Visit at <a target='blank' href='https://swaadhyayan.com/'>https://swaadhyayan.com/</a>  for detailed information of the best Digital Learning Platform for K-8 students.</div>
      </div>
  },
  {
    'id': 12,
    'banner': img11,
    'heading': `How to select the best Online Learning Platform for Elementary (K-8) students?`,
    'url': 'how-to-select-the-best-online-learning-platform-for-elementary-(k-8)-students',
    'date': 'MAY 16, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `In this modern era, with a wide variety of options available around you. It's important to evaluate different platforms and choose the one that aligns the best with your child's needs and goals. There are several excellent digital platforms for K-8 students, each with its unique strengths and features. Choosing the best online learning platform for elementary students can be a challenging task, especially with so many options. Getting confused among those is very obvious.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.msgText}>The Interactive curriculum of the LMS in India for K-8 must teach students the fundamentals of subjects in a fun and engaging way. Swa-Adhyayan is a school-based, the best Learning Management System that offers animated educational videos and interactive games for K-8 students in various subjects as per an individual’s needs, interests, and learning styles. The contents are aligned with National Curriculum Standards and are designed to be engaging and fun. To choose the best online learning platform for K-8 elementary students, here are some factors to be considered: </div>

        <ul>
          <li><b>Age-appropriate contents: </b>The platform should have age-appropriate contents which are engaging and interactive, and match the learning needs of the elementary students with respect to the subject. Online e-learning platform should include games, quizzes, and videos to keep students interested and motivated.</li>
          <li><b>Cost-effective User-friendly Interface and Accessibility: </b>Top online education platform must be cost-effective and hence, affordable for all. The adaptive digital learning platform must have a user-friendly interface that is easy to navigate for both students and teachers. It should be accessible from various devices, including computers, tablets, and smartphones, and have reliable internet connectivity.</li>
          <li><b>Comprehensive Curriculum: </b>The e-learning platform should provide a comprehensive curriculum that covers all the essential subjects, including Maths, Science, English, Social Studies, etc. We teach through gamification, designed to adapt to each student's skill level. The interactive lessons and games offered make an app or website to be the best online learning platform for teachers, students and parents.</li>
          <li><b>Teacher's Involvement: </b>Swa-Adhyayan provides dedicated dashboards for the beneficiaries with appropriate teacher's involvement, whether through live virtual sessions, video tutorials or teacher-monitored chapterwise assignments. Teachers can generate and assign the assessment sheets to the students for practice on the platform. </li>
          <li><b>Student's Progress Tracking: </b>The best LMS for K-8 students should have a student's progress tracking system that allows teachers and parents to monitor the progress of students. Reports provide personalised feedback to help the students to improve their Academics.</li>
        </ul>
        <div className={BlogCss.msgText}>Swa-Adhyayan, the best LMS in India for K-8 students encompasses all these factors, you can easily select it to be the best online learning platform for elementary students that meets the unique needs of the students. Visit the website on  <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a></div>
      </div>
  },
  {
    'id': 13,
    'banner': img12,
    'heading': `Merits of Self-learning via Swa-Adhyayan`,
    'url': 'merits-of-self-learning-via-swa-adhyayan',
    'date': 'JUNE 01, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Self-learning is the process of acquiring knowledge independently by taking responsibility for your own learning and actively seeking out information, resources, and experiences to enhance one's knowledge and abilities. That’s possible with Swa-Adhyayan, the Digital Learning Platform for K-8 students. Self-learning via Learning Management System (LMS) for K-8 students, offers personalised learning experiences tailored to each student's needs and abilities. From identifying learners’ strengths and weaknesses to providing authentic educational contents, from tracking students' progress to providing corrective approach for effective online learning, it offers all.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.msgText}>To have a guided Academic Journey; establish clear learning objectives and break down the goals into manageable steps. The maintenance of Digital Learning Platform is comparably more cost-effective in the long run. The best learning app caters to a large number of students simultaneously, making it scalable for schools/educational institutions. </div>
        <ol>
          <li><b>Flexibility and Convenience:</b> Self-learning allows students to learn at their own as they can access educational resources, assignments, and assessments anytime and anywhere, providing flexibility to accommodate their individual learning styles.</li>
          <li><b>Interactive Contents:</b> Swa-Adhyayan includes interactive digital learning resources such as videos, interactive quizzes, games, etc. Learning process is more enjoyable and encourages active participation, promoting better retention of information. Access to Diverse Learning Resources: Swa-Adhyayan LMS typically offers a wide range of learning resources, including e-books, educational videos, interactive lessons, and many more. This access to diverse resources allows students to explore different topics, reinforce their understanding, and delve deeper into subjects which interest them.</li>
          <li><b>Assessments and Feedback:</b> Swa-Adhyayan LMS provides continuous assessment and immediate feedback highlighting areas for improvement to help students to identify their strengths and weaknesses and adjust their learning strategies accordingly. Progress Tracking and Performances Analysis provides comprehensive tracking of students' performances. Teachers and parents can monitor students' achievements, identify areas where they may need additional support to ensure academic success.</li>
          <li><b>Enhanced Collaboration and Communication:</b> While self-learning implies an individual learning process, Swa-Adhyayan LMS can still facilitate collaboration and communication among students too. It often includes features like discussion forums, discussions, and virtual classrooms, enabling students to interact with their peers, ask questions, and share ideas and much more.</li>
          <li><b>Self-motivation and Responsibility:</b> Self-learning through an LMS like Swa-Adhyayan fosters self-motivation and instils a sense of responsibility in students. They become more independent in managing their learning journeys, setting goals, and taking ownership of their education.</li>
        </ol>
        <div className={BlogCss.msgText}>Swa-Adhyayan e-learning platform for students offers a range of benefits to empower the students by facilitating collaboration and continuous assessment. Self-learning can be rewarding as learners gain knowledge on their own terms. We facilitate parents-teachers communication and involvement to monitor children's progress, access reports to stay informed about their academic performances, fostering a collaborative approach to education.</div>
        

       
        <div className={BlogCss.msgText}>Visit: <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a> to know more.</div>
      </div>
  },
  {
    'id': 14,
    'banner': img13,
    'heading': `Swa-Adhyayan provides user friendly interface for students and associated Beneficiaries alike`,
    'url': 'swa-adhyayan-provides-user-friendly-interface-for-students-and-associated-beneficiaries-alike',
    'date': 'JUNE 07, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan, the best Digital E-learning platform for K-8 Students in India, brings the top notch facilities to study using online learning resources. The students can make use of top grade facilities to carry out dedicated communication centering on class specific subject based educational requirement. It also adds elements of digitalisation to the teaching-learning processes and allows the children to develop conceptual understanding by making use of the online reserves. Swa-Adhyayan, the best LMS software in India has extended the peripheries of online learning to net level by providing documents, 2D/3D videos, audios and PDFs.  `,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>Access the resources anywhere-anytime </div>
        <div className={BlogCss.msgText}>Swa-Adhyayan, the adaptive digital learning platform could be accessed on any device with a proper internet connection. The students with this facility will be able to study from anywhere and any topic while travelling or just going out for a walk, etc. As per their convenience, they can shift their online reading materials to larger screens of computers or smaller screens of cell phones. No geographical boundaries are restriction to learn anymore. </div>
        <div className={BlogCss.textHead}>Assessments made amiable </div>

        <div className={BlogCss.msgText}>To make learning easier, Swa-Adhyayan has been designed with a user-friendly interface for students and other beneficiaries. The teachers can assign the e-learning assessment tests to the students from anywhere at any time. The students are required to attempt the assigned tests in the stipulated time period only. The teachers can also fix the time limits after which the time period for attempting will expire and before which the examination paper will not be accessed. </div>

        <div className={BlogCss.textHead}>Reports and Homework certificate </div>
       
        <div className={BlogCss.msgText}>The Students/Parents/Educators are provided with timely Report Cards with dynamic graphical representation of children’s performances in weekly, monthly, half yearly and yearly tests. These Reports are provided in digital format and with the access on portal, it could be accessed anytime. The School Digital Learning Platform allows the authorities to form comparative reports for the entire school as well. Swa-sharing allows the exchange of documents via platform and makes these reports much more interactive than mere digital documents. </div>
        

       
        <div className={BlogCss.msgText}>Swa-Adhyayan helps the ‘Learners to become Achievers’ using their preferred learning styles. Here, teachers can evaluate students’ strengths and the areas to be worked upon to attain the best level of Academic Excellence. The suggestive measures and remedial lessons are also provided. To book a Demo Session visit <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a> </div>
      </div>
  },
  {
    'id': 15,
    'banner': img14,
    'heading': `Swa-Adhyayan, the best LMS to Learn, Assess and Share`,
    'url': 'swa-adhyayan-the-best-lms-to-learn-assess-and-share',
    'date': 'JUNE 23, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan is an elite e-learning platform that adds to the proficiency to learners’ skills. The best LMS to Learn, Assess and Share the resources has transformed the education system and brought it towards the successful concept of Hybrid Learning with an engaging approach. The Digital Learning Platform for K-8 students is well-equipped with the prerequisites of Learning Proficiency. The best Learning Management System proffers one step solution to schools, teachers, students and parents.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>Swa-sharing </div>
        <div className={BlogCss.msgText}>Swa-Adhyayan India’s Premier E-learning Platform brings students, teachers, parents and school management together and connects them digitally over the website. The connectivity offered by highly adaptive Digital Learning Platform can be taken outside of schools and information sharing can also take place in between the heads of the schools. The connectivity offered by the platform can be used for sharing Progress Reports and General Informations about the students between the parents and teachers. Homework and assignments can also be shared with parents of the students. Swa-Adhyayan makes use of the online networking and creates a world of Digital Education in a newer phase of life. </div>

        <div style={{textAlign:'center', marginTop:10}}>“Swa-Adhyayan is a New Age Educational Networking Platform.”</div>

        <div className={BlogCss.textHead}>Swa-learning </div>

        <div className={BlogCss.msgText}>The multiskilled platform permits learning in the subjects English, Hindi, Mathematics, Science, Social Science, EVS, and Art and Craft. For students, it offers the perfect blend of customisable activities, games, multimedia resources, educational movies with practice exercises and assessments. The online contents can be assessed on any device such as PCs, laptops and smart phones. With the help of the best LMS for K-8 students, homework can be assigned to them in multiple ways. The home assignments can be asked to submit as in images, PDFs, Audios, Visuals or any other formats.  </div>

        <div className={BlogCss.textHead}>Swa-assessment </div>
       
        <div className={BlogCss.msgText}>The Digital Learning Platform allows to create assignments to develop Multiple Intelligences of the children keeping the school curriculum in consideration. Hence, the assignments prepared with the help of the platform are predominantly based on the prescribed syllabus. Swa-Adhyayan offers a user friendly interface in which there is no proficiency in Technological Aspects. The creation of interactive assessments is easy with the help of Swa-Adhyayan LMS. It provides the facility to rectify the lessons when mistakes take place. Seamless feedback mechanism can be adopted here as students and teachers are part of this platform that is made especially for the purpose of exchanges encouraging education and learning in students.  </div>
       
        <div className={BlogCss.msgText}>Learn more about the ADLP on <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a> </div>
      </div>
  },
  {
    'id': 16,
    'banner': img15,
    'heading': `Swa-Adhyayan, the best LMS with extended Learning Opportunities`,
    'url': 'swa-adhyayan-the-best-lms-with-extended-learning-opportunities',
    'date': 'JUNE 26, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan, the best LMS for K-8 students in India offers simpler yet engaging ways to study for the students interested in both online and offline offerings. Swa-learners are provided with the facility of hybrid mode of learning. The digital learning platform with its vast features extend the learning peripheries. With books and online resources, the Learning Management System for K-8 students forms stronger conceptual base. The interactive e-learning assessments available on the adaptive digital learning platform ensures conceptual development of each student. It considers the growth and academic proficiency of the learners and develop their scientific tempers and attitude via gamification and educational inputs. Swa-Adhyayan makes learning extendable and flexible in two ways:`,
    'content':
      <div className={BlogCss.msgText}>
        <ol>
          <li className={BlogCss.msgText}><b>Swa-Adhyayan as a Hybrid Mode of Learning</b></li>
            <ul>
                <li>The students can make use of both online and offline learnings.</li>
                <li>Swa-Adhyayan LMS and SWAR App, the best Online learning apps for students to make learning engaging and productive.</li>
                <li>Learning is possible anytime anywhere.</li>
                <li>Chapterwise Assessments are provided on the adaptive e-learning platform for additional practice of the learnt concepts.</li>
                <li>No stress of missing the concepts in class as they can learn it on the portal.</li>
                <li>Assessments based Reports are generated on the platform and can be seen anytime. </li>
                <li>The students can gauge their academic progress throughout the session.</li>
                <li>The Digital Portal for K-8 is supported by NEP 2020 aligned textbooks, workbooks and other resource materials.</li>
          </ul>
          <li className={BlogCss.msgText}><b>Swa-Adhyayan as Learning Management System for K-8</b>
            <div style={{marginTop:10}}>Swa-Adhyayan LMS facilitates both learning and teaching by involving all the educators and parents in child’s education. The responsibility to manage and administering the digital learning resources involved in the educational process came into existence pre dominantly to make learning easier than ever.  The contents are provided to the students under the guidance of teachers and the school administration. The students must practise 60k+ questions available on the platform as per the latest syllabus, and they extend their reasoning and aptitude capabilities using latest technology. The students here can access textbook based exercises online that reinstate the learning processes in students.</div>
          </li>
        </ol>
        <div  className={BlogCss.msgText}>Swa-Adhyayan is the top choice of the learners as well as the educators. The teachers can generate e-learning assessments for K-8 students through the top Online Learning Platform for students as per their learning requirements. LEARN the new concepts, ASSESS your knowledge and SHARE the digital learning resources for K-8 such as audios, documents, videos, video links, etc., with the best LMS with extended learning opportunities and learn through Gamification. </div>
        <div className={BlogCss.msgText}>Visit: <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a> for more information.</div>
      </div>
  },
  {
    'id': 17,
    'banner': img16,
    'heading': `Swa-Adhyayan, the Digital Learning Platform for Schools with hybrid offerings`,
    'url': 'swa-adhyayan-the-digital-learning-platform-for-schools-with-hybrid-offerings',
    'date': 'JULY 03, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan, School Learning Management System, with its vast deliverables offers multiple facilities in Hybrid Model. This brings forth both online and offline learning amenities. The students on the platform can avail the most advanced digital assistance in the field of education. Through the best LMS software in India for K-8, the educators can generate classwise/subjectwise full course assessments for the students. Swa-Adhyayan provides wide range of questions to ease out the intricate pattern of learning.`,
    'content':
      <div className={BlogCss.msgText}>
        <div className={BlogCss.textHead}>What does Swa-Adhyayan stand for? </div>
        <div style={{textAlign:'center'}}>“Swa-Adhyayan itself means Self-learning.”</div>
        <div className={BlogCss.msgText}>With its innovative learning resources and dedicated deliverables, Swa-Adhyayan has become the most recommended School Learning Management System.  The students are provided with multiple facilities on the portal which serves as a learning guide as well as resourceful determinant for education. The detailed analytical report available on the Digital Learning Platform for Schools helps the authorities to evaluate the progress made by the students throughout the Academic Year. </div>

        <div className={BlogCss.textHead}>Swa-learners stand at the base of development</div>
        <div style={{textAlign:'center',}}>“Swa-Adhyayan inculcates habit of Swa-learning among students.”</div>
        <div className={BlogCss.msgText}>The aim of education is to make the students self-reliant and also to make them a part of the ever evolving educational process. The digital contents available on the best LMS software in India for K-8 in the form of texts to videos have everything that students might require to learn on their own through experimentation, exploration and excellence.</div>
        <div className={BlogCss.textHead}>Remedial lessons for relocating back on the track</div>
        <div className={BlogCss.msgText}>The students failing to perform well in assessments, can look for remedial measures offered in the classrooms or even on Swa-Adhyayan LMS. The students can understand tougher concepts with the help of teachers at school. They can also make use of the SEPT Analysis Test to identify their unique learning styles, weaknesses and strengths, etc.<br/><br/>
        Swa-Adhyayan being recognised as the best Digital Learning Platform for Schools has made education engaging. It looks after the needs of the students right from the beginning. The platform is supported by the NEP aligned textbooks, workbooks, worksheets, etc. Multiple Reports are generated on the platform to evaluate Swa-learners’ performances. We amalgamate the joint efforts of all the stakeholders to smoothen the educational journey of the students of Kindergarten to Grade 8.
        </div>
        <div className={BlogCss.msgText}>For More Detail contact us on 011-23285568 or write us at <b>info@swaadhyayan.com</b></div>
      </div>
  },
  {
    'id': 18,
    'banner': img17,
    'heading': `Unique features of Swa-Adhyayan, the best LMS software for K-8 students for fascinating outcomes`,
    'url': 'unique-features-of-swa-adhyayan-the-best-lms-software-for-k-8-students-for-fascinating-outcomes',
    'date': 'JULY 05, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan is an easily Adaptive Digital Learning Platform (ADLP) specifically designed for K-8 education. With the Hybrid mode of learning, it offers several features which make it highly suitable and flexible for the learners of this age group. Let’s have a quick review on few of them:`,
    'content':
      <div className={BlogCss.msgText}>
        <ol>
          <li className={BlogCss.msgText}><b>User-friendly Interface: </b>Swa-Adhyayan provides a simple and intuitive interface that is easy for young students to navigate. Its user-friendly design ensures that even children with limited technological experience can use the system without difficulty.
          <div style={{textAlign:'center', marginTop:20, fontStyle:'italic'}}>“Something is interesting only when it’s understandable.”</div>
          </li>
          
          <li className={BlogCss.msgText}><b>Customisable Practice Materials: </b>The best LMS software in India allows teachers to customise the practice materials based on the specific needs and requirements of their students. They can easily create and modify lessons, assignments, and assessments, tailoring them to suit the diverse learning styles and abilities of K-8 students.</li>
          <li className={BlogCss.msgText}><b>Interactive Contents: </b>Swa-Adhyayan offers interactive learning materials such as videos, animations, quizzes, and gamification. These engaging resources help to grab the attention of young learners, making them more interested in learning process and make it effectively enjoyable.</li>

          <li className={BlogCss.msgText}><b>Progress Tracking: </b>The Adaptive Digital Learning Platform for students includes a comprehensive progress tracking feature that allows teachers and parents to monitor students’ performances. This way educators can identify areas where students may need additional support and also provide personalised feedback and assistance wherever required.</li>

          <li className={BlogCss.msgText}><b>Collaboration and Communication: </b>The E-learning platform for students facilitates collaboration and communication between teachers, students, and parents. It includes features such as discussion boards, chat functionality, and messaging systems, allowing students to interact with their peers and teachers in a safe and monitored environment.
          <div style={{textAlign:'center', marginTop:20, fontStyle:'italic'}}>“Communication builds linking Connections.”</div>
          </li>

          <li className={BlogCss.msgText}><b>Parental Involvement: </b>Recognising the importance of parental involvement in a child’s education, Swa-Adhyayan allows parents access to their child's progress reports, assignments, and communication with teachers to promote parents’ active participation in their child's learning journey. </li>
          <li className={BlogCss.msgText}><b>Mobile Compatibility: </b>The best letsMS software in India is compatible with mobile devices, allowing students to access learning materials on smartphones or tablets. This flexibility ensures that students can access the digital learning resources anytime, anywhere, fostering a blended learning environment.</li>
        </ol>

        <div className={BlogCss.textHead}>In Culmination…</div>
        
        <div  className={BlogCss.msgText}>Swa-Adhyayan's user-friendly interface, customisable curriculum, interactive contents, progress tracking, collaboration and communication tools, parental involvement features, and mobile compatibility make it the most recommended E-learning platform for students. It effectively supports the unique needs and learning styles of young students while providing teachers and parents with valuable tools for monitoring and facilitating their educational journey.</div>
      </div>
  },
  {
    'id': 19,
    'banner': img18,
    'heading': `The future seeks to adopt Digital mode of Learning with Swa-Adhyayan`,
    'url': 'the-future-seeks-to-adopt-digital-mode-of-learning-with-swa-adhyayan',
    'date': 'JULY 11, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Swa-Adhyayan is a Digital Learning Management System that enables the students to make the most of the said online learning offerings. The adaptive learning platform provides the access to e-learning assessment prepared by experts for the students as per the latest syllabus. Envisioning ultimate success in mind, the students learn through more engaging and interactive learning resources. Swa-Adhyayan, is the best LMS software in India to provide both online and offline offerings for sure-shot Academic Success.`,
    'content':
      <div className={BlogCss.msgText}>
        <ul>
          <li className={BlogCss.msgText}><b>Accept the Online Learning Process</b>
          <div style={{marginTop:10}}>Online Learning resources utilises the modern day technology for conceptual understanding. It is different from conventional learning resources that require the students’ presence in a classroom. It follows its own process and requires a certain level of understanding to benefit the learners. The user friendly interface of the platform ensures the optimum utilisation of the resources that can be accessed anywhere anytime.</div> 
          </li>
          
          <li className={BlogCss.msgText}><b>Set your Goals</b>
          <div style={{marginTop:10}}>Limit your screen time by setting a goal and follow the pattern strictly. Stay committed to your study time.  Extended screen time while browsing irrelevant online learning websites or developing an eye fatigue, is common. So the crux of the whole scenario here is that one should take constructive steps towards the study plan with the best Digital Educational platform. Navigate the facilities provided at the platform and learn using the online resources.</div></li>

          <li className={BlogCss.msgText}><b>More is Always Less </b>
          <div>To get the best out of a platform, one need to know all about it. How to use it, what are its various benefits, etc. Never leave out on utilising any facility on pretext that it is less required. Swa-Adhyayan, Digital Learning Platform K-8 offers a wider range of educational derivatives to the students including audios, picture videos, animated videos, Gamification and much more. From NEP aligned textbooks to textbooks based worksheets, workbooks, e-learning assessments, Swa-Adhyayan offers all.</div>
          </li>

          <li className={BlogCss.msgText}><b>Perceive and Progress</b>
          <div style={{marginTop:10}}>Learn about the deliverables, build a connection between them to plan your preparation strategy and score beyond your expectations. The flexibility offered by the online learning resources, brings convenience to Swa-learners. Student E-Learning Portal provides all learning accompaniments to establish Academic Excellence. Perceive the most recommended adaptive e-learning platform for K-8 to the fullest and grab the golden opportunity to experience the best learning experience ever.</div></li>
        </ul>
        <div  className={BlogCss.msgText}>Learn more about Swa-Adhyayan on <a target='blank' href='https://swaadhyayan.com/ '>https://swaadhyayan.com/</a> </div>
      </div>
  },
  {
    'id': 20,
    'banner': img19,
    'heading': `Swa-Adhyayan: LMS for Modern Learning equipped with NEP Integrated Tools (NITs) for advance opportunities`,
    'url': 'swa-adhyayan-lms-for-modern-learning-equipped-with-nep-integrated-tools-nits-for-advance-opportunities',
    'date': 'DECEMBER 11, 2023',
    'postby': 'SWA-ADHYAYAN',
    'msg': `Hybrid learning experience makes the students blend well with the current demands of 21st century. Swa-Adhyayan has been ranked as the best platform aligned with NEP 2020 guidelines ensures holistic learning for K-8 students.`,
    'content':
    <div className={BlogCss.msgText}>
    <div className={BlogCss.msgText}>Worksheets provided on the best LMS software in India are a very important teaching as well as the learning resource for educators & students respectively. They help the child to learn holistically through the LAR (Learn Apply Resolve) approach. Extensive opportunities for applied learning are provided through the worksheets both in physical format as well as in Digital mode. </div>
    <div style={{textAlign: "center"}}>
    <img src={blogTable} alt="" style={{width:"60%"}}/>

    </div>
    <div className={BlogCss.msgText}>Swa-Adhyayan, a comprehensive digital learning platform provides impeccable offline & online teaching-learning tools to the teachers & students respectively. The separate dashboard dedicatedly designed for teachers enables them to develop holistic understanding of NEP aligned e-learning platform to generate e-assessments at great ease. Online Resources provide the base to generate topic wise, subject wise tests. The facility of the tests gets progressive support in the form of reports to boost digital learning. </div>
    <div className={BlogCss.msgText}>Important pieces of information could be shared among the users in the form of PDFs, Info-graphics, images, videos etc. The facility of Swa-Sharing also helps in establishing communication between the stakeholders of the best online learning platform for K-8. The platform provides education based environment bringing various stake holders such as teachers, parents, school authorities & students together that greatly benefit the students by enhancing Academic Performance and preparing them for Future Success.  Student Digital Learning Platform permits knowledge acquisition through various means, where Gamification ranks at the top of all.</div>
    <div className={BlogCss.textHead}>Advantages of opting for a LMS for K-8</div>
    <ul>
          <li style={{marginTop:10}}>Customized teaching-learning approach for Swa-Learners to get a personal experience.</li>
          <li style={{marginTop:10}}>Makes learning more immersive with no scope of losing interest in studies. </li>
          <li style={{marginTop:10}}>Enables the students and teachers both to have an enriching experience.</li>
          <li style={{marginTop:10}}>Self-Evaluation and Feedback to the performance both are easier than ever.</li>
          <li style={{marginTop:10}}>26 types of Reports leaves no scope of negligence to achieve nothing other than best.</li>
          <li style={{marginTop:10}}>Content is accessible anytime, anywhere.</li>
          <li style={{marginTop:10}}>Teachers can gauge their students’ Academic Growth throughout the session.</li>
          <li style={{marginTop:10}}>From creating e-assessments for k-8 to evaluating them and generating Reports, Swa-Adhyayan LMS covers it all.</li>
        </ul>
    <div className={BlogCss.msgText}>The platform provides diversity in the matter of learning by providing both digital & offline learning resources. The students can accelerate their Academic Growth by making use of e-assessments, worksheets and interactive activities including those of the digitally accredited system of learning. Swa-Adhyayan, the most recommended digital e-learning site for Kindergarten to Grade 8 promotes digitally aided learning as it believes that <br/>
    <div style={{textAlign:'center', fontStyle:'italic', marginTop:20}}>“Learning can be done anytime and anywhere in the world.”</div>
    <div className={BlogCss.msgText}>
      Therefore, with an intent to take learning one step ahead, Swa-Adhyayan allows the students to access its various features at any given point of time as per their own convenience.  This states that learning on the go or learning in the house, the platform facilitates all.  Tougher concepts are easier to be grasped with the use of interactive digital resources that are complementary & supplementary to the text book material. The apex learning platform extends the benefits of online learning application to students through the single click. 
    </div>
    </div>
    <div className={BlogCss.msgText}> To know more about Swa-Adhyayan visit <b>https://swaadhyayan.com/</b></div>
  </div>
  },
];