import  './App.css';
import  './Animatecss.css';
import Header from './components/coman/header/Header';
import Footer from './components/coman/footer/Footer';
import Navbar from './components/coman/navbar/Navbar';
import HomePage from './components/home/Home';
import AboutUsPage from './components/aboutUs/Aboutswa';
import ServicesPage from './components/services/Services';
import Event from './components/event/Event';
import Blog from './components/blog/Blog';
import ContactUsPage from './components/contactUs/ContactUs';
import CareerPage from './components/career/Career';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import Terms from './components/terms/terms-and-conditions';
import DisclaimerPage from './components/disclaimer/disclaimer';
import { Routes,  Route} from "react-router-dom";
import { useEffect, useState } from 'react';
import CBSEPariksha from './CBSEPariksha';
import BlogFullView from './components/blog/BlogFullView';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { PopNewLunch } from './components/coman/navbar/PopNewLunch';
import OurProduct from './components/OurProduct/OurProduct';
import ScrolTop from './components/ScrolTop';
import { Helmet } from 'react-helmet';

import NewErrorPage from'./components/Error404'

// import NewLunch from './components/img/NEW-LOUNCHING-ICON.png'
function App() {


 useEffect(() => {
  AOS.init();
}, [])

 
  return (
  <>
    <div className="App">
      <Header/>
        <Navbar/>
      <Helmet>
        <title>Digital Learning Platform for Classes K to 8 | Swa Adhyayan LMS</title>
        <meta name="description" content="Swa Adhyayan an adaptive best E-learning platform for students, Parents, Teachers and Schools based on Multiple Intelligences includes learning & assessment processes both." />
        <meta property="og:title" content="Digital Learning Platform for Classes K to 8 | Swa Adhyayan LMS" /> 
        <meta property="og:description" content="Swa Adhyayan an adaptive best E-learning platform for students, Parents, Teachers and Schools based on Multiple Intelligences includes learning & assessment processes both"/> 
        <meta property="og:url" content="https://swaadhyayan.com/" /> 
        <meta property="og:site_name" content="Swa Adhyayan" /> 
        <meta property="og:image" content="https://swaadhyayan.com/static/media/banner3.27e148c4.png" /> 
        <meta name="twitter:site" content="@swaadhyayan"/> 
        <meta name="twitter:title" content="Digital Learning Platform for Classes K to 8 | Swa Adhyayan LMS"/>
        <meta name="twitter:description" content="Swa Adhyayan an adaptive best E-learning platform for students, Parents, Teachers and Schools based on Multiple Intelligences includes learning & assessment processes both" />
        <meta name="twitter:image" content="https://swaadhyayan.com/static/media/banner3.27e148c4.png"/>
       
      </Helmet>
              <ScrolTop/>
        <Routes>
          <Route path="/" exact element={<HomePage/>}/>
          <Route path="/aboutUs" element={<AboutUsPage/>} />
          {/* <Route path="OurProduct/:id" element={<OurProduct/>} /> */}
          <Route path="/services" element={<ServicesPage/>} />
          <Route path="/event" element={<Event/>} />
          <Route path="/blog" element={<Blog/>}/>
          <Route path="/blog/:url" element={<BlogFullView/>}/>
          <Route path="/contactUs" element={<ContactUsPage/>} />
          <Route path="/career" element={<CareerPage/>} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<Terms/>} />
          <Route path="/disclaimer" element={<DisclaimerPage/>} />
          <Route path="/CBSEPariksha" element={<CBSEPariksha/>} />
          <Route path="/*" exact element={<NewErrorPage/>}/> 
        </Routes>
      <Footer/>

    
    </div>
    </>
  );
}

export default App;
