import Maudhri from '../../img/authors/madhuri.webp';
import Manchanda from '../../img/authors/manchanda.webp';
import Dhawan from '../../img/authors/dhawan.webp';
import DeepakGupta from '../../img/authors/DeepakGupta.webp';
import geetaBudhiraja from '../../img/authors/geetaBudhiraja.webp';
import harpreetLikhari from '../../img/authors/harpreetLikhari.webp';
import manjuPanda from '../../img/authors/manjuPanda.webp';
import skGoel from '../../img/authors/skGoel.webp';
import VikashVijay from '../../img/authors/VikashVijay.webp';
import TestimonialCss from './testimonial.module.css';


export default [
    {
        'id': 1,
        'img': Maudhri,
        'name': 'Dr. N.Madhuri Parti',
        'designation': '(An Academic Director, Aravali)',
        'msg':<>
        <div className={TestimonialCss.peragraphStyle}>Dr. N.Madhuri Parti is a visionary and futuristic educationist. She has a vast experience in setting up and leading schools. She has played a vital role in the evolution of the learning experience. She has been with Educomp and Shri Ram Schools as a Group Director. Presently, she is an Academic Director at Aravali International School, Faridabad. She has also spearheaded Lakshmipat Singhania Education Foundation.</div>
        <div className={TestimonialCss.peragraphStyle}>With an experience of over two decades, she has been actively involved in building learning environments, developing out of the box curricula and promoting experiential leaning. She has brought a paradigm shift in the learning framework and executed the 21st century learning methodology, which includes collaborative learning adding creativity, innovation and honing the critical thinking and problem-solving skills of students.</div>
        <div className={TestimonialCss.peragraphStyle}>She is focused on producing creative and innovative thinkers by providing key insight for educators, parents and students. She has trained over 10000 teachers, 2000 school leaders and 600 employees from various corporations within the country and globally. She is a left brain and right brain trainer who has founded the bump to baby co., where she trains moms-to-be to bring a new generation which is more grounded and can handle life better.</div>
        <div className={TestimonialCss.peragraphStyle}>As an author, a writer and a blogger, she works for various’ social causes under the umbrella of Rotary International. The concerns are Teach India, Generation, Polio and the Youth Empowerment.</div>
        <div className={TestimonialCss.peragraphStyle}>Ms. Parti has been phenomenal in bringing a 1800 change in the Pedagogy of the Aravali International School and leading it into the global thinking process.</div>
        <div className={TestimonialCss.boldText}>She has been honoured at national and international levels – </div>
        <div className={TestimonialCss.peragraphStyle}>Received the International Professional of the Year award by the International Biographical Centre, England.</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>Featured in the Marquis Who’s Who book, honouring successful individuals from different spheres of life.</li>
            <li>Received the Doors to Diplomacy Platinum Award by the U.S. State Department for facilitating a project on International Relations and Diplomacy.</li>
            <li>Received the Excellence In education Award by Rajiv Gandhi Education foundation</li>
            <li>Received the award for ‘Four Avenues of Service’ from Rotary International Chicago.</li>
        </ul>
        <div className="">She is the author of ‘Together with—‘New Mathematics’ and ‘Mathematics Buzz!!’ textbooks published by Rachna Sagar Pvt. Ltd.</div>
        </>
        

    },
    {
        'id': 2,
        'img': Manchanda,
        'name': 'Mr. R P Manchanda',
        'designation': 'Ex-Director and Principal of Pathfinder Global schools.',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        He has teaching experience of 40 years in various institutions including Aakash for NEET and IIT JEE. He is a dynamic and an innovative educationist. He is a mentor for large number of teachers and students associated with Rachna Sagar. He encourages out of the box methods of teaching and learning, critical thinking and emphasises on developing analytical skills.
        </div>
        <div className={TestimonialCss.boldText}>He has received several awards such as:</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>Dr Radha Krishnan and Dronacharya Award from Avantika.</li>
            <li>Together with Chemistry received Second prize in 2011.</li>
        </ul>
        <div className={TestimonialCss.boldText}>He has been associated with many organization and conducted workshops and seminars.</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>Live telecast for teachers and students by Edusat.</li>
            <li>Conducted more than 100 seminars and webinars for students and teachers under Seedhi baat, author ke saath.</li>
        </ul>
        <div className={TestimonialCss.peragraphStyle}>He has authored of more than 30 books published by Rachna Sagar Pvt. Ltd. Author of CUET 2022 for chemistry. Lately, he has developed LMS content for class 10,11,12 including Live experiment available on YouTube of Enlive education.He has been associated with Rachna Sagar Pvt. Ltd. since 1996 and extended his knowledge by authoring many books.</div>
        </>
    },
    {
        'id': 3,
        'img': Dhawan,
        'name': 'Mr. Rajesh Kumar Dewan',
        'designation': '[ M.Sc. (Mathematics), B.ED.]',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        Rajesh Kumar Dewan is a visionary and futuristic educationist. He has a vast experience of 48 years in the teaching of Mathematics with children of all ages. Thereby understanding their mind set and moulding the teaching methodology according to their needs ... and presenting to them in the form of teaching/understanding/evaluating/self assessment. He has changed the methodology according to the present and futuristic needs based on 21st century learning methodology with deep penetration into collaborative learning, innovative creativity, critical thinking and problem solving skills.
        </div>
        <div className={TestimonialCss.peragraphStyle}>He has been associated with the delegation to Nepal to study and suggest  improvements needed, if any, in the education system. Ha has been associated with CAMET programme taken up by CBSE and British Government. He has been a guide and paper setter for different school.</div>
        <div className={TestimonialCss.peragraphStyle}>
        Based on above facts he has clubbed his experience in the form of books at different levels.
        </div>
        <div  className={TestimonialCss.boldText}>He has been associated with Rachna Sagar Pvt. Limited for over 30 years and has written:</div>
        <div className={TestimonialCss.peragraphStyle}>Together With Mathematics (Class XI and XII), Enrich Mathematics (Classes IX to XII), 20 – 20 Mathematics series (classes IX to XII), Pariksha Papers (Classes X and XII) to name a few.</div>
        <div className={TestimonialCss.peragraphStyle}>Besides he has provided guidance and expertise in many books of ICSE board.</div>
        <div className={TestimonialCss.boldText}>He has been recognised and rewarded at different levels and intervals:</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>Lion’s Club Award on Teacher’s day.</li>
            <li>Akhil Bhartiya Sawatantra Patrakar and Lekhak Sangh(Regd) award on Teacher’s day.</li>
            <li>Rachna Sagar Pvt. Ltd. Pariksha award.</li>
            <li>Centennial Celebrations of Montfort Brothers 2003- An Accolade of distinction award.</li>
            <li>RWA- Ajay Enclave Association award on Teacher’s day</li>
            <li>Montfort Brothers of St. Gabriel-Silver Jubilee of the Province of Delhi award 2014.</li>
        </ul>
        </>
    },
    {
        'id': 4,
            'img': DeepakGupta,
        'name': 'Mr. Deepak Gupta',
        'designation': '[B.Com. ; M.Com. ; CA (I)], Director & Founder STG Commerce Classes',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        Mr. Deepak Gupta is the Founder and Head Faculty of STG Commerce Classes. He has a vast experience for counselling in various schools and played a vital role in build up the career path for thousands of students. Mr Deepak Gupta is also assoc ...iated with <b>RACHNA SAGAR PVT LTD</b> as Author of <b>TOGETHER WITH ACCOUNTANCY</b>. He was also presented the Conceptual Video Lectures related to this book, which are available on <b>RACHNA SAGAR DIGITAL PLATFORM</b>.
        </div>
        <div className={TestimonialCss.peragraphStyle}>These lectures helps thousands of students to make concept clarity in their academic performance. He also inbuilt some innovative ideas in Together With books like Flow Chart, Case Study Base questions which help to improve the thinking and intelligence power of students.</div>
        <div className={TestimonialCss.peragraphStyle}>
        Mr Gupta has also organized motivational workshop for students. He is also associated with digital partner with Study Khazana. With an experience of over 25 years in academic field, he has been actively involved in building learning environments, he also designed the innovative learning methods for board students which help them to achieve their goal with stress less environment.
        </div>
        <div className={TestimonialCss.peragraphStyle}>He has also been honoured as <b>Excellence Award 2021-22</b> as best online teacher during Covid period by Urban Pro Learning Platform.</div>
        <div>Overall Mr. Gupta is a personality who inspire many students and also parents about the technique of smart learning with understanding.</div>
        <div  className={TestimonialCss.boldText}>He always believe that :</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>Education is understanding, is not spoon-feeding</li>
            <li>Education is a process, not the few hours before exams.</li>
        </ul>
        <div className={TestimonialCss.boldText}>Thought of Mr. Gupta</div>
        <div className={TestimonialCss.peragraphStyle}>I believe that when god call us to teach, he promises the strength & wisdom to do it well. Over the year, I have various students who just does not seem that  interested in learning. Some of them appear lazy, others are disorganised. To activate and motivate them, first of all we should believe in our students. Our words can be extremely powerful in inspiring them. Since 1997, I have achieved many positive response from thousands of students through proper motivational counselling.</div>
        
        </>
    },

    {
        'id': 5,
        'img': VikashVijay,
        'name': 'Dr. Vikas Vijay',
        'designation': 'Popularly Known As AccountsGuru®, B.Com (Hons),M.Com., B.ed.,CWA(I).,M.Phil.,L.L.B., Master Trainer NLP',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        Dr. Vikas Vijay is India’s Leading CBSE Commerce Teachers and Students’ Trainer. Dr. Vikas Vijay has worked as a  PGT Commerce at the New Era Public School, New Delhi for a period of 15 years. He has also worked as a visiting lect ...urer at Delhi University.
        </div>
        <div className={TestimonialCss.peragraphStyle}>He is the author of several books in his subject. His bestseller books are Together with Accountancy and Business Studies which has received huge demand among the student community.</div>
        <div className={TestimonialCss.peragraphStyle}>
        He is also a master Trainer of NLP – <b>Neuro</b> Linguistic programming  <b>trained by World No.1 trainer 'TONYROBBINS'</b>.
        </div>
        <div className={TestimonialCss.peragraphStyle}>He has been a CBSE Teachers' and Students' Trainer from the last 25 years. He is also known as the Founder of mnemonic ,new innovative and scientiﬁc techniques in Commerce stream.</div>
        <div className={TestimonialCss.peragraphStyle}>Dr. Vikas has conducted workshops for students and teachers, in his subject, particularly on topics such as:</div>
        <div className={TestimonialCss.peragraphStyle}>Train the Trainers Workshops for Teachers to maximize their Potential and find a “New You” within themselves.</div>
        <div  className={TestimonialCss.boldText}>Peak Performance Workshops for Class XIIth Students, to achieve their Dream success in Board Exams .</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>CUET workshops for Students and parents.</li>
            <li>Personal Branding workshops for Teachers and Students.</li>
            <li>Seedhi Baat with Dr. Vikas Vijay to unleash your True Potential,</li>
        </ul>
        <div className={TestimonialCss.peragraphStyle}>to Know the importance of Smart ork along with hadork, Imporance of will er skillHigher Oder Thinking Skills and handy sueons cack the CBSE and as well as CUET Examination.</div>
        <div className={TestimonialCss.peragraphStyle}>Dr. Vias has also shaed his wide knwlede and xpertise with the CBSE in the delopmet of Acouancy pojects or Class XII. He has also ored with CIE, NCET or pomoting "Etepeneurial India.</div>
        <div className={TestimonialCss.peragraphStyle}>Dr. Vikas has been the founder of Full  Marks®-an educational  research and  assessment institute “Raﬄesia Education  Society”.</div>
        
        </>
    },

    {
        'id': 6,
        'img': skGoel,
        'name': 'Mr. S. K. Goel',
        'designation': '[M.Sc. in Physics, B.Ed]',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        S.K. Goel is an iconic figure in the educational market since 1983. He has been teaching Physics for the last 35 years at secondary and senior secondary levels; and is associated with teaching-learning material for science at secondary levels. His ... most popular books as an author are‘Together with Science’ study material for class 10.
        </div>
       
        <ul className={TestimonialCss.indentStyle}>
            <li>‘Together with Mathematics’ study material for class 9.  </li>
            <li>Lab manual for class 9 & 10.</li>
            <li>Enriched Scanner for class 10 Science.</li>
        </ul>
        <div className={TestimonialCss.peragraphStyle}>He has been associated with Rachna Sagar for over 16 years and has contributed immensely in Together with Science for Class 10 (previous years paper) and giving insights of how to write concise answers in examinations.</div>
        <div className={TestimonialCss.peragraphStyle}>Besides these books, he has worked in Physics Lab Manual for classes 11 and 12 and AIEEE Physics book. All these books are in popular demand among the student community.</div>
        <div className={TestimonialCss.peragraphStyle}>Currently, he is working on Rachna Sagar’s Learning Management System (LMS), an online project, where he is developing content for class 10 - Science.</div>
        <div className={TestimonialCss.peragraphStyle}>S. K. Goel follows a specific teaching style wherein he prefers to direct the students to search for answers by giving them practical application of the concepts instead of giving them the correct answer directly. It enhances their thought process as giving a little nudge in the right direction helps them calculate the correct answer.</div>
        
        </>
    },

    {
        'id': 7,
        'img': geetaBudhiraja,
        'name': 'Mrs. Geeta Budhiraja',
        'designation': '[M.A. (B.Ed.)]',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        She has over 41 years of teaching experience. She has taught at New Public School, Chandigarh and Bal Bharat Public School, Pitampura. She also taught for a short span of time at Ravindra Public School, Pitampura. She was the head Examiner (Hindi) ... for CBSE board in the year 2014. With an experience of over four decades, she is a well-know resource person and has conducted many training sessions for In-service officers. One such training programme was ‘Development of Teachers’ organized BBPS Training Centre (CBSE Empanelled) at PitamPura, Delhi, in the year 2013 and 2014. She has been facilitated for conducting productive and inspirational workshops and seminars across India. She has judged various Hindi debates and competitions over the years.
        </div>
        <div className={TestimonialCss.peragraphStyle}>Her long years of teaching career made her understand students’ needs and therefore she decided to venture into writing creative books to make learning easy.</div>
        <div className={TestimonialCss.peragraphStyle}>She has authored 50 titles in Hindi Grammar and Literature textbooks for  classes 1 to 10.</div>
        <div className={TestimonialCss.peragraphStyle}>She has also written text books for classes 1 to 8 and Sample Papers for classes 9 and10.</div>
        <div className={TestimonialCss.peragraphStyle}>She has been associated with Rachna Sagar since 1996, and has authored Grammar books  from Classes 1 to 8.</div>
        
        </>
    },
    {
        'id': 8,
        'img': manjuPanda,
        'name': 'Mrs. Manju Panda',
        'designation': '[B.A., B.Ed., M.A. M. Phil, NET]',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        Manju Bal Krishna Panda is an alumnus of Hindu College, Delhi University and did her post-graduation in Philology from BSU, Russia. She joined JNU, New Delhi as a research scholar as well as taught in the School of International Studies and Delhi University .... She first tried her footing in school education in K. R. Mangalam, New Delhi. Soon, she moved to the NCR and became a part of the school administration in a range of semi-urban, urban, and semi-rural schools in Haryana, Rajasthan, and NCR. She has an experience of over two decades of teaching English to high school students in CBSE affiliated schools, more than 12 years of school administration, teachers’ training, curriculum development, and developing content for school students in English language and literature. 
        </div>
        <div className={TestimonialCss.peragraphStyle}>She has done content reviewing, authored books on English Grammar and Composition and Value Education, drafted teachers’ manuals, set question papers for Olympiads, and drafted lesson plans for teachers.</div>
        <div className={TestimonialCss.peragraphStyle}>She has also published stories, and poems in popular Indian print magazines, e-magazines, South east Asian Literary Journal and American literary journals which have been highly appreciated.</div>
        <div className={TestimonialCss.peragraphStyle}>She has won multiple Best Teacher awards, Awards for Best Board results (X), Awards for Best Board results (XII), and Dronacharya Award for Best Principal.</div>
        <div className={TestimonialCss.headingStyle}>Associated with RachnaSagar since 2021</div>
        <div className={TestimonialCss.boldText}>Books authored/content developed for RachnaSagar</div>
        <ul className={TestimonialCss.indentStyle}>
            <li>English Language and Literature Pull-out Worksheets -Classes 10 and 9.</li>
            <li>Competency-based & Objective type Questions- Class 11 and 12(Term-1 and Term -2).</li>
            <li>CBSE Sample Papers English Core EAD - Class 12 ( Term 1 and Term 2).</li>
            <li>CBSE Sample Papers English Core EAD- Class 10 ( Term 1 and Term 2).</li>
            <li>CUET UG 2022</li>

        </ul>
        
        </>
    },
    {
        'id': 8,
        'img': harpreetLikhari,
        'name': 'Mrs. Harpreet Likhari',
        'designation': 'Hod Foreign Languages',
        'msg': <>
        <div className={TestimonialCss.peragraphStyle}>
        I am a language trainer and a passionate educator with over 26 years of experience in teaching senior secondary students. Dedicated, resourceful, and goal-driven professional educator with a solid commitment to the social and academic growth and development ... of every child. An accommodating and versatile individual with the talent to develop inspiring hands-on lessons that will capture a child's imagination and breed success. I am an encouraging educator with the ability to make language a skill rather than a subject through various digital tools. Provide analogies, and group activities and prepare teaching plans that facilitate classroom participation. While pursuing my graduation, I discovered my intense passion for learning languages and literature. This new interest led me to complete my advanced diploma in French and German, followed by Masters in French from PU, Chandigarh. I have had the opportunity to extend my French language skills in the most prestigious school G.D Goenka Public School, Vasant Kunj, ND. I have been holding the position of the HOD of Foreign Languages for the last 8 years. I was honored to work with Rachna Sagar from the year 2015 to the present. I was so impressed with their professionalism and moreover, it felt amazing that they valued the ideas that I shared. Even during the pandemic, I received regular updates on my submitted work. The communication was very simple and the editors involved in the processing of our manuscript were prompt and helpful in any/all queries throughout the publication process. I have written and edited workbooks and books for the middle section (classes 5-8), namely “Enchanté” and “Accueil” a series of books ranging from 0-4 for all classes for the middle section. 
        </div>
        </>
    },

]