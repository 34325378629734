import React from 'react'
import './Founder.css'
import Hrsir from '../../img/HRSir.webp'
import RajeevSir from '../../img/RajeevSir.webp'
import LalitSir from '../../img/LalitSir.webp'
import NeerajSir from '../../img/NeerajSir.webp'

export default function Founder() {
    return (
        <>
            <div className="container-fluid founderComp">
            <div className="container FounderBox">
                <div className="row headPart">
                    <h2><span className="clrhead">Meet Our </span> <span className="head">FOUNDERS</span> </h2>
                     <hr /> 
                </div>
                <div className="row founder1"  data-aos="zoom-in"  data-aos-duration="1500">
                    <div className="col-md-3 founderImg">
                        <div className="imgCircle">
                            <img src={Hrsir} alt="" />
                        </div>
                    </div>
                    <div className="col-md-9 aboutFounder1">
                        <h2 className="heading1">SHRI HR GUPTA</h2>
                        <h6 className="subhead">Brand Ambassador of Swa-Adhyayan</h6>
                        <p>A visionary with over five decades of experience in the Indian education system, Shri HR Gupta has held numerous esteemed positions in various educational organisations. The following is a glimpse of the positions held by him.
                        <ul className="topicPoints">
                            <li> Secretary, CBSE</li>
                            <li>Controller of Examination (AIPMT), CBSE</li>
                            <li>Joint Secretary (Administration and Academics), CBSE</li>
                            <li>Joint Director, National Open School</li>
                            <li>Member of Governing Body, College of Sciences, University of Delhi</li>
                            <li>Member of Delhi University Court</li>
                        </ul>
                        He has always been a firm believer of transforming the primitive ways of teaching in order to make learning and teaching more effective and meaningful.</p>
                    </div>
                </div>


                <div className="row founder1"  data-aos="zoom-in"  data-aos-duration="1500">
                    <div className="col-md-3 founderImg">
                        <div className="imgCircle">
                            <img src={LalitSir} alt="" />
                        </div>
                    </div>
                    <div className="col-md-9 aboutFounder1">
                        <h2 className="heading1">MR. LALIT GUPTA</h2>
                        <h6 className="subhead">CMD at Forever Books Pvt Ltd</h6>
                        <p>Graduated in B.E. (Mechanical) from Delhi College of Engineering in the year 1987, Mr. Lalit Gupta is an accomplished and dedicated professional with over twenty-five years of  experience.</p>
                        <p> He is an expertise in educational books publishing industry having passion to explore innovative solutions and creativity. He is closely involved in  supervision and development of  an online learning platform  along with offline teaching learning materials for all the stakeholders including students, teachers and parents.</p>
                    </div>
                </div>


                <div className="row founder1"  data-aos="zoom-in"  data-aos-duration="1500">
                    <div className="col-md-3 founderImg">
                        <div className="imgCircle">
                            <img src={NeerajSir} alt="" />
                        </div>
                    </div>
                    <div className="col-md-9 aboutFounder1">
                        <h2 className="heading1">MR. NEERAJ GUPTA</h2>
                        <h6 className="subhead">Managing Director</h6>
                        <p>Neeraj Gupta, the Managing Director of Rachna Sagar Private Limited has been steering the publication of educational contents for almost three decades and has successfully bagged international tenders for producing educational content for schools of various countries such as Ghana, Uganda, Nigeria, Rwanda and Middle East National Educational Boards.</p>
                        <p>Under his dynamic leadership, RSPL has entered the partnership with the Government of India for the National Skill Development Corporation (NSDC). Our success is borne out by the fact that so far, in more than twenty-five years of publishing under his leadership, over 100 of our publications have bagged awards from the Federation of Indian Publishers and other representative bodies, making us one of the most awarded publishers of the country.</p>
                        <p>He has taken lead in contributing towards the national initiative through an NGO—The Vriddhi Education Society – which besides working towards making youngsters lifelong learners and responsible citizens, imparts skill training, leading to entrepreneurship development, through our school programme. </p>
                        <p>He had shouldered the responsibility of General Secretary of the Association of School Books Publishers (ASBP). He also plays an active role as the Vice-President in the Federation of Educational Publishers in India (FEPI) and the member of Federation of Indian Publishers (FIP). Furthering the spirit of camaraderie amidst the Indian educational books publishing community, he has initiated a Publishers Cricket League for Indian Publishers and successfully organised the first edition of the Championship matches in New Delhi, India.</p>
                        <p>In a nutshell, he is committed constantly towards improving all aspects of teaching-learning process and has an innate desire to create a better tomorrow.</p>
                    </div>
                </div>



                <div className="row founder1"  data-aos="zoom-in"  data-aos-duration="1500">
                    <div className="col-md-3 founderImg">
                        <div className="imgCircle">
                            <img src={RajeevSir} alt="" />
                        </div>
                    </div>
                    <div className="col-md-9 aboutFounder1">
                        <h2 className="heading1">DR. RAJEEV GUPTA</h2>
                        <h6 className="subhead">Founder Director of Swa-Adhyayan</h6>
                        <p>Emphasising on his motto “Let’s Change the way India Teaches”, CA Dr. Rajeev Gupta has evolved as a transformational leader. He is a strategic advisor and has worked with individuals, groups, corporations and educational institutions. Having over 20 years of vast global work experience, his training models and empowerment workshops systematically allow a personalised understanding and fulfilment of goals.</p>
                        <p>He has a sacred passion of promoting universal human values in the educational institutions. Through his incomparable values in teacher training programs, parenting programs and multiple intelligences based teaching, Dr. Rajeev Gupta has helped many schools in incorporating value-infused methodologies for successful fulfilment of their aims and objectives.</p>
                    </div>
                </div>



               

            </div>
            </div>
        </>
    )
}
