import React, {useEffect} from 'react';
import OurProcss from '../OurProduct/Offline.module.css';
import {useParams} from 'react-router-dom';
import { Container, Row, Col} from 'react-bootstrap';
import item3 from '../img/adminDashboard1/b3.webp';
import item4 from '../img/adminDashboard1/b4.webp';
import item5 from '../img/adminDashboard1/b5.webp';
import item6 from '../img/adminDashboard1/b6.webp';
import item7 from '../img/adminDashboard1/b7.webp';
import item8 from '../img/adminDashboard1/b8.webp';
import item9 from '../img/adminDashboard1/b9.webp';
import item10 from '../img/adminDashboard1/b10.webp';



export default function Forteacher() {
  const productId = useParams()
  useEffect(()=>{
    window.$("#OfflineSlide").owlCarousel({
        items:4,
        itemsDesktop:[1000,3],
        itemsDesktopSmall:[979,3],
        itemsTablet:[768,3],
        pagination:false,
        navigation:true,
        navigationText:["",""],
        autoPlay:true,
    }); 
    
  }, 
  
  []);


  
  return (
      <div className={OurProcss.bgMain1}>

        <Container className={OurProcss.ContainDiv1}>
          <div className={OurProcss.OnlineProductHeading}>For Teachers</div>  

              <Row id="OfflineSlide" className={`${OurProcss.owl_carousel} ${OurProcss.ItemDiv}`} >
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox} ${OurProcss.pink}`}>
                          <div className={OurProcss.imgDiv}><img src={item3} alt="" /></div>
                          <h3 className={OurProcss.title}>Teachers' Resource Manuals</h3>
                      </div>
                  </Col>

                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}  ${OurProcss.green}`}>
                          <div className={OurProcss.imgDiv}><img src={item4} alt="" /></div>
                          <h3 className={OurProcss.title}>My Big Story Book(A-Z)</h3>
                      </div>
                  </Col>
                  
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`} >
                          <div className={OurProcss.imgDiv}><img src={item5} alt="" /></div>
                          <h3 className={OurProcss.title}>Subject Charts (A-8)</h3>
                      </div>
                  </Col>
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`}>
                          <div className={OurProcss.imgDiv}><img src={item6} alt="" /></div>
                          <h3 className={OurProcss.title}>Classroom Charts (A-8)</h3>
                      </div>
                  </Col>
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`}>
                          <div className={OurProcss.imgDiv}><img src={item7} alt="" /></div>
                          <h3 className={OurProcss.title}>Educational Games (A-5)</h3>
                      </div>
                  </Col>
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`}>
                          <div className={OurProcss.imgDiv}><img src={item8} alt="" /></div>
                           <h3 className={OurProcss.title}>Subject Table Calendars (A-C)</h3>
                      </div>
                  </Col>
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`}>
                          <div className={OurProcss.imgDiv}><img src={item9} alt="" /></div>
                          <h3 className={OurProcss.title}>Motivational Stickers (A-8)</h3>
                      </div>
                  </Col>
                  <Col className="Col-12">
                      <div className={`${OurProcss.serviceBox}`}>
                          <div className={OurProcss.imgDiv}><img src={item10} alt="" /></div>
                          <h3 className={OurProcss.title}>Educational Manipulatives</h3>
                      </div>
                  </Col>
              </Row>

        </Container>
      </div>
  )
}
