import React from 'react';
import  './Aboutswa.css';

import { Container } from 'react-bootstrap'
import about from '../../img/AboutUs-Animated-banner-1.gif';
import swaImg from '../../img/aboutUs.webp';

function Aboutswa() {
    return (
        <>
        <div className="container-fluid bannerBox">
            <img className="bannerAbout" src={about}/>
        </div>

        <div className="container-fluid welcomeswa">
        <div className="container">
            <div className="row welcomeBlock g-1">
                <div className="col-md-7 welcomeText">
                    <h2><span className="clrhead">Welcome to </span> <span className="head">SWA-ADHYAYAN</span> </h2>
                    <p style={{textAlign:'justify', marginTop:"40px"}}>Swa-Adhyayan LMS is a product developed by the IT wing of Forever Books Pvt. Ltd. which ensures high quality contents as per the guidelines of National Education Policy, 2020. It provides a revolutionary way to learn by offering Learning, Assessment and Sharing for students to empower and encourage them to build their skills.</p>
                    <p style={{textAlign:'justify'}}>This product has been designed and led by a team of visionaries, educators and professionals to captivate the attention of learners, improve their talents and escalate their intellects. This platform went digital at a time when the teaching-learning process turned more towards online learning. It helped many institutions to educate students constantly with its hybrid learning.</p>
                    <p style={{textAlign:'justify'}}>This Adaptive Digital Learning Platform (ADLP) includes features such as SEPT (Swa-Adhyayan Entrant’s Profiling Test), Swa-learning, Swa-assessment, Swa-sharing, Homework app, and Reports. The Assessment for classes 1 to 8 generates multiple tests and reports and the progress cards of the children based on numerous parameters. It is supported by textbooks, workbooks, teachers’ resource manuals, competitive kits, NCERT kits, CBSE Pariksha, etc. Its feature, ‘Learning by Gamification’ improves the kinesthetic skills of the learners, adding to experiential learning or hands-on learning. The purpose of   this product is to deliver globally and to ensure that learners acquire better learning outcome by using the resources shared on this platform.</p>
                </div>
                <div className="col-md-5 welcomeImg p-0" data-aos="zoom-in"  data-aos-duration="1500">
                    <img src={swaImg} alt="" />
                </div>
            </div>
        </div>
        </div>
        </>


    )
}

export default Aboutswa
