import { Col, Container, Row } from 'react-bootstrap';
import OfferCss from './offer.module.css';
import Sept from  '../../img/sept.png';
import Learning from  '../../img/learning.png';
import Assessment from  '../../img/assessment.png';
import Report from  '../../img/report.png';
import Sharing from  '../../img/sharing.png';
import {useEffect} from 'react';
import TimeTable from '../../img/Time-table.png';
import marksEntry from '../../img/marksEntry.png';
import homework from '../../img/homework.png';




function Offer() {
  useEffect(()=>{
    window.$("#offerSlide").owlCarousel({
    items:4,
    itemsDesktop:[1000,3],
    itemsDesktopSmall:[979,3],
    itemsTablet:[768,3],
    pagination:false,
    navigation:true,
    navigationText:["",""],
    autoPlay:true,
    }); 
  
  }, 
  
  []);
  
  return (
    <div className={OfferCss.offerBack}>
      <Container className={OfferCss.mainContainer}>
        <div className={OfferCss.whatweofffer}>Online Features of</div>
        <h2 className={OfferCss.ADLPDiv}>ADAPTIVE DIGITAL LEARNING PLATFORM (ADLP)</h2>  

        <Row id="offerSlide" className={OfferCss.owl_carousel}> 
          
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={OfferCss.serviceBox}>
                <div className={OfferCss.service_icon}>
                    <span><img className={OfferCss.iconimgs} src={Sept}/></span>
                </div>
                <h3 className={OfferCss.title}>Sept</h3>
                <p className={OfferCss.description}>India's first unique pre-learning diagnostic and profiling test based on the award winning concept of Multiple Intelligences. </p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.orange}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={Learning}/></span>
                </div>
                <h3 className={OfferCss.title}>Learn [K-8]</h3>
                <p className={OfferCss.description}>Learning module inclusive of all subjects in a school curriculum under different boards with 1500+ practice exercises per subject per class. </p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.green}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={Assessment}/></span>
                </div>
                <h3 className={OfferCss.title}>Assess [K-8]</h3>
                <p className={OfferCss.description}>Making it easy to create assessments for teachers and assign the sets of assessment as classwork or as homework.</p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.green1}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={Report}/></span>
                </div>
                <h3 className={OfferCss.title}>Reports</h3>
                <p className={OfferCss.description}>Engaging and stimulating feedback system. It offers a large number of valuable reports for all users deployed on the platform.</p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.blue}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={Sharing}/></span>
                </div>
                <h3 className={OfferCss.title}>Sharing</h3>
                <p className={OfferCss.description}>This feature provides communication tools for students and teachers including chat, messenger, forum and blog.</p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.green}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={TimeTable}/></span>
                </div>
                <h3 className={OfferCss.title}>Timetable</h3>
                <p className={OfferCss.description}>The school can update the timetable and assign it to the teachers on respective days. </p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.orange}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={marksEntry}/></span>
                </div>
                <h3 className={OfferCss.title}>Marks entry</h3>
                <p className={OfferCss.description}>This feature enables school to keep records on various parameters and helps in declaration of result. </p>
            </div>
          </Col>
          <Col className="col-md-12 col-sm-12 col-12">
          <div className={`${OfferCss.serviceBox} ${OfferCss.green1}`}>
                <div className={OfferCss.service_icon}>
                <span><img className={OfferCss.iconimgs} src={homework}/></span>
                </div>
                <h3 className={OfferCss.title}>Homework</h3>
                <p className={OfferCss.description}>Homework can be assigned in different formats to students. Teachers can check the homework of the students and it can be viewed by the parents. </p>
            </div>
          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default Offer;
