import React,{useState} from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import PenCss from './Pen.Module.css';
import penImg from '../../img/PenUser.png';
import PenADPL from '../../img/trm/penadlp.png'
import PenWork from '../../img/trm/PenWorkshop.png'
import ReactImageMagnify from 'react-image-magnify';
import mapImg300 from "../../img/map300.png";
import mapImg1200 from "../../img/map1200.png"
import Map from '../../img/PenUserMap.png'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import SchoolList from './SchoolList';
const PanIndiaUserMap = () => {
    

    return (
        <>
            <Container className='py-4'>
            <div className={PenCss.heading}>pan india users (Swa-adhyayan lms)</div>
            <div className={PenCss.mainContainer1}>
                <div className={PenCss.mapContainer} data-aos="zoom-in"  data-aos-duration="1500">
                    {/* jk*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Jammu & Kashmir</Tooltip>}>
                        <span className={PenCss.jk}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    {/* ladhak*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Ladakh</Tooltip>}>
                        <span className={PenCss.ladhak}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                    {/* himanchal*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Himanchal</Tooltip>}>
                        <span className={PenCss.himanchal}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    {/* punjabb*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Punjab</Tooltip>}>
                        <span className={PenCss.punjab}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    {/* Dehradun*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Uttarakhand</Tooltip>}>
                        <span className={PenCss.uk}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    {/* delhi*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delhi</Tooltip>}>
                        <span className={PenCss.dl}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    {/* Hr*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Haryana</Tooltip>}>
                        <span className={PenCss.hr}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                    {/* RJ*/}
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Rajasthan</Tooltip>}>
                        <span className={PenCss.rj}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* up*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Uttar Pradesh</Tooltip>}>
                        <span className={PenCss.up}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Bihar*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Bihar</Tooltip>}>
                        <span className={PenCss.bihar}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Sikkim*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Sikkim</Tooltip>}>
                        <span className={PenCss.sikkim}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Arunchal Pradesh*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Arunchal Pradesh</Tooltip>}>
                        <span className={PenCss.ap}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/*Assam*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Assam</Tooltip>}>
                        <span className={PenCss.assam}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/*Nagaland*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Nagaland</Tooltip>}>
                        <span className={PenCss.nagaland}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/*Manipur*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Manipur</Tooltip>}>
                        <span className={PenCss.manipur}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/*Meghalay*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Meghalay</Tooltip>}>
                        <span className={PenCss.meghalay}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/*Tripura*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tripura</Tooltip>}>
                        <span className={PenCss.tripura}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/*Mizoram*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Mizoram</Tooltip>}>
                        <span className={PenCss.mizoram}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Gujrat*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Gujrat</Tooltip>}>
                        <span className={PenCss.gujrat}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* Mp*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Madhya Pradesh</Tooltip>}>
                        <span className={PenCss.mp}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Chhattishgarh*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Chhattishgarh</Tooltip>}>
                        <span className={PenCss.Chhattishgarh}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                    
                     {/* Jh*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Jharkhand</Tooltip>}>
                        <span className={PenCss.jharkhand}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* westBengal*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">West Bengal</Tooltip>}>
                        <span className={PenCss.wb}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* mh*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Maharashtra</Tooltip>}>
                        <span className={PenCss.mh}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* Odisha*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Odisha</Tooltip>}>
                        <span className={PenCss.odisha}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* telangana*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Telangana</Tooltip>}>
                        <span className={PenCss.telangana}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}



                     {/* Goa*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Goa</Tooltip>}>
                        <span className={PenCss.goa}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* Karnataka*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Karnataka</Tooltip>}>
                        <span className={PenCss.karnataka}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* Andhra Pradesh*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Andhra Pradesh</Tooltip>}>
                        <span className={PenCss.andhraPradesh}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                     {/* Kerala*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Kerala</Tooltip>}>
                        <span className={PenCss.kerala}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}

                     {/* tamil Nadu*/}
                     <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tamil Nadu</Tooltip>}>
                        <span className={PenCss.tamilnadu}>
                            <div disabled style={{ pointerEvents: 'none', }}>
                            </div>
                        </span>
                    </OverlayTrigger>
                    {/*  */}


                </div>
                {/* <div className={PenCss.listContainer} data-aos="zoom-in"  data-aos-duration="1500"><SchoolList/></div> */}
            </div>


            </Container>

            {/* <Container>
            <div className={PenCss.penUserHeading}>PAN India Users Map</div>
            <div style={{width:'500px', white:'600px', margin:'auto'}}>
                        <ReactImageMagnify {...{
                            smallImage: {
                                alt: 'Wristwatch by Ted Baker London',
                                isFluidWidth: true,
                                src: mapImg300
                            },
                            largeImage: {
                                src: mapImg1200,
                                width: 1200,
                                height: 1800
                            }
                        }} />
                    </div>
            

            </Container> */}

        </>
    )

};

export default PanIndiaUserMap;
